import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const initialData = [
  {
    date: new Date(2022, 3, 21),
    name: "John Doe",
    notes: "Lorem ipsum dolor sit amet.",
  },
  {
    date: new Date(2022, 5, 10),
    name: "Jane Smith",
    notes: "Consectetur adipiscing elit.",
  },
  {
    date: new Date(2022, 2, 5),
    name: "Bob Johnson",
    notes: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

export default function MuiTableWithSort({ initialData }) {
  const [data, setData] = useState(initialData);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  console.log(initialData.length);

  const handleSort = (key) => {
    if (orderBy === key) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(key);
      setOrder("asc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "date"}
                direction={order}
                onClick={() => handleSort("date")}
              >
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={order}
                onClick={() => handleSort("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "notes"}
                direction={order}
                onClick={() => handleSort("notes")}
              >
                Notes
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.date}>
              <TableCell>Hello</TableCell>
              <TableCell>Hello</TableCell>
              <TableCell>Hello</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
