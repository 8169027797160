// import validator from "validator";

const upperCaseRegex = /[A-Z]+/;
const lowerCaseRegex = /[a-z]+/;
const digitsRegex = /[0-9]+/;
const specialCharRegex = /[^A-Za-z0-9]+/;

function checkStrongPassword(password) {
  let strongPasswordErrors = "";

  if (password.length < 8) {
    strongPasswordErrors += "Password must be at least 8 characters long. ";
  }

  if (!upperCaseRegex.test(password)) {
    strongPasswordErrors +=
      "Password must have at least 1 uppercase character. ";
  }

  if (!lowerCaseRegex.test(password)) {
    strongPasswordErrors +=
      "Password must have at least 1 lowercase character. ";
  }

  if (!digitsRegex.test(password)) {
    strongPasswordErrors += "Password must have at least 1 digit. ";
  }

  if (!specialCharRegex.test(password)) {
    strongPasswordErrors += "Password must have at least 1 special character. ";
  }

  return strongPasswordErrors;
}

const validators = {
  user: {
    setupAccount: (name, password, confirmPassword) => {
      const validationErrors = {};

      if (name.trimStart().trimEnd().length < 3) {
        validationErrors.name = "Name must be at least 3 characters long.";
      }

      const strongPasswordErrors = checkStrongPassword(password);
      if (strongPasswordErrors !== "") {
        validationErrors.password = strongPasswordErrors;
      }

      if (confirmPassword !== password) {
        validationErrors.confirmPassword = "Passwords do not match.";
      }

      return validationErrors;
    },
  },

  patients: {
    addNew: (
      name,
      dateOfBirth,
      gender,
      bloodGroup,
      dateOfDemise,
      imageFile
    ) => {
      name = name.trimLeft().trimRight();

      const validationErrors = {};
      if (name.length < 3) {
        validationErrors.name = "Name must be at least 3 characters long.";
      }

      if (dateOfBirth === null) {
        validationErrors.dateOfBirth = "Date of Birth is required.";
      }

      if (gender === null || gender === "") {
        validationErrors.gender = "Gender is required.";
      }

      if (imageFile === null) {
        validationErrors.imageFile = "An Image is required.";
      }

      if (
        dateOfBirth !== null &&
        dateOfDemise !== null &&
        dateOfDemise.getTime() < dateOfBirth.getTime()
      ) {
        validationErrors.dateOfDemise =
          "Date of Demise cannot be before Date of Birth.";
      }

      return validationErrors;
    },
  },

  patientLogs: {
    addNew: (selectedPatient, selectedPoint, notes, imageNotes) => {
      const validationErrors = {};
      if (selectedPatient === null) {
        validationErrors.patient = "No Patient selected.";
      }
      if (selectedPoint === null) {
        validationErrors.point = "No Point selected.";
      }

      if (notes.trimStart().trimEnd().length === 0) {
        validationErrors.notes = "No notes provided.";
      } else if (notes.trimStart().trimEnd().length < 3) {
        validationErrors.notes = "Please provide some proper notes. ";
      }

      validationErrors.imageNotes = [];
      let invalidImageNotesCount = 0;
      for (let i = 0; i < imageNotes.length; i++) {
        if (imageNotes[i] === "") {
          validationErrors.imageNotes.push("No notes");
          invalidImageNotesCount++;
        } else {
          validationErrors.imageNotes.push("");
        }
      }
      if (invalidImageNotesCount === 0) {
        delete validationErrors.imageNotes;
      }

      return validationErrors;
    },
    addNew2: (selectedPatient, selectedPoint, imageNotes) => {
      const validationErrors = [];
      if (selectedPatient === null) {
        validationErrors.push("No patient selected.");
      }
      if (selectedPoint === null) {
        validationErrors.push("No point selected.");
      }
      for (let i = 0; i < imageNotes.length; i++) {
        if (imageNotes[i] === "") {
          validationErrors.push(
            `Image ${
              i + 1
            } does not have any notes. All images must have notes.`
          );
        }
      }
      return validationErrors;
    },
    search: (
      patientId,
      pointId,
      startTimestamp,
      endTimestamp,
      notes,
      prescription
    ) => {
      // restrict number of query params lower limit?
      const validationErrors = [];

      if (
        startTimestamp !== undefined &&
        endTimestamp !== undefined &&
        endTimestamp < startTimestamp
      ) {
        validationErrors.endTimestamp =
          "End Date & Time cannot be less than Start Date & Time.";
      }

      return validationErrors;
    },
  },

  patientDocuments: {
    addNew: (
      patientId,
      documentType,
      documentTitle,
      documentIssueDate,
      documentNotes,
      documentFile
    ) => {
      documentType = documentType.trimStart().trimEnd();
      documentTitle = documentTitle.trimStart().trimEnd();
      documentNotes = documentNotes.trimStart().trimEnd();

      const validationErrors = {};

      if (patientId === undefined) {
        validationErrors.patientId = "Patient cannot be blank.";
      }

      if (documentType === "") {
        validationErrors.documentType = "Document type cannot be blank.";
      }

      if (documentTitle === "") {
        validationErrors.documentTitle = "Document title cannot be blank.";
      }

      // if (documentNotes === "") {
      //   validationErrors.documentNotes = "Document notes cannot be blank.";
      // }

      if (documentFile === null) {
        validationErrors.documentFile = "Document file cannot be blank.";
      }

      return validationErrors;
    },
  },
};

export default validators;
