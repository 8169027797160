import { useState, useContext, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Navigate, useParams } from "react-router-dom";

import userContext from "../context/userContext";
import apiServicesClient from "../api/apiServices";

const PerformResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const { resetPasswordToken } = useParams();

  const [loading, setLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorText, setErrorText] = useState("");

  const resetPasswordButtonOnClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    // check if password and confirm are equal
    if (password !== confirmPassword) {
      toast("Passwords do not match!");
      setLoading(false);
      return;
    }

    const response = await apiServicesClient.user.performResetPassword(
      resetPasswordToken,
      password
    );
    if (!response.success) {
      toast(response.error.message);
      console.log(response.error);
      setLoading(false);
      return;
    }

    toast("Password Reset successfully.");

    setLoading(false);

    setTimeout(() => navigate("/auth/login"), 5000);
  };

  const verifyResetPasswordTokenAndId = async () => {
    const response = await apiServicesClient.user.verifyResetPasswordToken(
      resetPasswordToken
    );

    if (!response.success) {
      setErrorText(response.error.message);
      setIsTokenValid(false);
      setLoading(false);
      return;
    }

    setEmail(response.result.userDetails.email);
    setName(response.result.userDetails.name);
    setIsTokenValid(true);
    setLoading(false);
  };

  useEffect(() => {
    verifyResetPasswordTokenAndId();
  }, []);

  if (user) {
    return <Navigate to="/auth/double-auth-attempt" />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h3">Reset Your Password</Typography>
      </Box>

      <Box display="flex" justifyContent="center" mb={0}>
        <Typography variant="h5">{name}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h5">{email}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={0}>
        <TextField
          className={classes.inputTextField}
          type="password"
          label="Password"
          value={password}
          disabled={!isTokenValid}
          onChange={(event) => setPassword(event.target.value)}
        />
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <TextField
          className={classes.inputTextField}
          type="password"
          label="Confirm password"
          value={confirmPassword}
          disabled={!isTokenValid}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h6" color="error">
          {errorText}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={resetPasswordButtonOnClick}
          disabled={loading || !isTokenValid}
        >
          {loading ? (
            <BeatLoader color="white" loading={true} size={10} />
          ) : (
            "Reset Password"
          )}
        </Button>
      </Box>
    </Container>
  );
};

export default PerformResetPassword;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
}));
