import { useContext, useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import userContext from "../context/userContext";
import Select from "../components/Select";
import { saveToLocalStorage } from "../localStorage/localStorage";

export default function SortingKeySelect({
  sortingKey,
  setSortingKey,
  allSortingKeys,
}) {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      maxWidth="sm"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      mb={2}
    >
      <Box mr={2}>
        <Typography sx={{ fontWeight: "bold" }}>Sort By:</Typography>
      </Box>

      <Box>
        <Select
          selectedValue={sortingKey}
          setSelectedValue={(newValue) => setSortingKey(newValue)}
          allValues={allSortingKeys}
          mb={0}
        />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  selectMain: {
    width: "100%",
  },
}));
