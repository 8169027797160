import dayjs from "dayjs";

import { mediaBaseUrl } from "./apiEndpoints";

function getAbsoluteFileUrl(imageUrl) {
  return `${mediaBaseUrl}/${imageUrl.slice(6)}`;
}

function parsePatientDocumentObjectForJS(patientDocument) {
  patientDocument.documentFileUrl = `${mediaBaseUrl}/${patientDocument.documentFileUrl.slice(
    6
  )}`;

  if (patientDocument.documentIssueDate !== undefined) {
    patientDocument.documentIssueDate = new Date(
      patientDocument.documentIssueDate
    );
  }

  patientDocument.createdAt = new Date(patientDocument.createdAt);
  patientDocument.updatedAt = new Date(patientDocument.updatedAt);

  return patientDocument;
}

function parsePatientLogObjectForJs(patientLog) {
  if (patientLog?.patient?.avatarImageUrl !== undefined) {
    patientLog.patient.avatarImageUrl = getAbsoluteFileUrl(
      patientLog.patient.avatarImageUrl
    );
  }

  if (patientLog.images !== undefined) {
    for (let i = 0; i < patientLog.images.length; i++) {
      if (patientLog.images[i] !== null) {
        patientLog.images[i].url = getAbsoluteFileUrl(patientLog.images[i].url);
      }
    }
  }

  patientLog.createdAt = new Date(patientLog.createdAt);
  patientLog.updatedAt = new Date(patientLog.updatedAt);
  patientLog.logDatetime = new Date(patientLog.logDatetime);

  return patientLog;
}

// Done
function parsePatientObjectForFrontend(patient) {
  if (patient.imageUrl !== undefined) {
    patient.imageUrl = getAbsoluteFileUrl(patient.imageUrl);
  }

  if (patient.avatarImageUrl !== undefined) {
    patient.avatarImageUrl = getAbsoluteFileUrl(patient.avatarImageUrl);
  }

  patient.dateOfBirth = (new Date(patient.dateOfBirth));

  if (patient.dateOfDemise !== undefined) {
    patient.dateOfDemise = (new Date(patient.dateOfDemise));
  } else {
    patient.dateOfDemise = null;
  }
  if (patient.bloodGroup === undefined) {
    patient.bloodGroup = "";
  }
  if (patient.lastSeenAt?.datetime !== undefined) {
    patient.lastSeenAt.datetime = new Date(patient.lastSeenAt.datetime);
  }

  patient.createdAt = new Date(patient.createdAt);
  patient.updatedAt = new Date(patient.updatedAt);

  // parse address, phoneNumber, etc here if required

  return patient;
}

// Done
function parsePatientDocumentObjectForFrontend(patientDocument) {
  if (patientDocument?.patient?.avatarImageUrl !== undefined) {
    patientDocument.patient.avatarImageUrl = getAbsoluteFileUrl(
      patientDocument.patient.avatarImageUrl
    );
  }
  if (patientDocument?.documentFileUrl !== undefined) {
    patientDocument.documentFileUrl = getAbsoluteFileUrl(
      patientDocument.documentFileUrl
    );
  }

  if (patientDocument.documentIssueDate !== undefined) {
    patientDocument.documentIssueDate = new Date(
      patientDocument.documentIssueDate
    );
  } else {
    patientDocument.documentIssueDate = null;
  }

  if (patientDocument.documentNotes === undefined) {
    patientDocument.documentNotes = "";
  }

  patientDocument.createdAt = new Date(patientDocument.createdAt);
  patientDocument.updatedAt = new Date(patientDocument.updatedAt);
  return patientDocument;
}

function addLabelToPatient(patient) {
  patient.label = patient.name + " - ";

  if (patient.lastSeenAt?.point?.name !== undefined) {
    patient.label += patient.lastSeenAt.point.name;
  } else {
    patient.label += "No Logs";
  }

  return patient;
}

function parsePointObjectForFrontend(point) {
  if (point.imageUrl !== undefined) {
    point.imageUrl = getAbsoluteFileUrl(point.imageUrl);
  }

  if (point.avatarImageUrl !== undefined) {
    point.avatarImageUrl = getAbsoluteFileUrl(point.avatarImageUrl);
  }

  return point;
}

export {
  parsePatientObjectForFrontend,
  parsePatientDocumentObjectForJS,
  parsePatientLogObjectForJs,
  addLabelToPatient,
  parsePointObjectForFrontend,
  parsePatientDocumentObjectForFrontend,
};
