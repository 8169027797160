import { useNavigate } from "react-router-dom";
import { Box, Avatar, Typography, Link } from "@mui/material";

export default function SinglePatientDisplayer({ patient, mb }) {
  const navigate = useNavigate();

  const navigateToPatientDetailsPage = () => {
    if (patient._id === undefined) {
      return;
    }

    navigate(`/patients/${patient._id}`);
  };

  return (
    <Box
      maxWidth="sm"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      mb={mb !== undefined ? mb : 2}
    >
      <Box width="15%" mr={2}>
        <Avatar
          src={patient?.avatarImageUrl}
          alt={patient !== "" ? patient?.name : "-"}
          sx={{
            width: 50,
            height: 50,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => navigateToPatientDetailsPage()}
        />
      </Box>

      <Box width="70%">
        <Typography
          sx={{
            fontWeight: 700,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => navigateToPatientDetailsPage()}
        >
          {patient?.name}
        </Typography>
      </Box>

      <Box width="15%" ml={2}></Box>
    </Box>
  );
}
