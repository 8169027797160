import { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { appName } from "../config/appConfig";
import { getPathnameWithoutParams } from "../utils/jsUtils";

const navbarTitlesForPathnames = {
  "/": appName,
  "/patients/search": "Search Patients",
  "/patient-logs/add": "Add Patient Log",
  "/patient-logs/search": "Search Patient Logs",
  "/settings": "Settings",
  "/patients/add": "Add Patient",
  "/patients": "Patient Details",
  "/patient-documents/add": "Add Patient Document",
  "/patient-documents": "Patient Document",
  "/patient-documents/for-patient": "Documents Of Patient",
  "/patient-logs/": "Patient Log",
  "/patient-logs/for-patient": "Logs Of Patient",
};

const locationsToHideNavbarOn = [
  "/auth/register",
  "/auth/login",
  "/auth/double-auth-attempt",
];

const locationsToHideBackButtonOn = [
  "/",
  "/patients/search",
  "/patient-logs/add",
  "/patient-logs/search",
  "/settings",
  "/auth/request-reset-password",
  "/auth/perform-reset-password",
  "/auth/perform-setup",
];

export default function Navbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [navbarTitle, setNavbarTitle] = useState(appName);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);

  const handleBackButtonClick = (event) => {
    navigate(-1);
  };

  useEffect(() => {
    const pathnameWithoutParams = getPathnameWithoutParams(location.pathname);
    setNavbarTitle(navbarTitlesForPathnames[pathnameWithoutParams]);
    setShowNavbar(!locationsToHideNavbarOn.includes(pathnameWithoutParams));
    setShowBackButton(
      !locationsToHideBackButtonOn.includes(pathnameWithoutParams)
    );
  }, [location, params]);

  if (!showNavbar) {
    return null;
  }

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {showBackButton ? (
            <IconButton color="inherit" onClick={handleBackButtonClick}>
              <ArrowBackIcon />
            </IconButton>
          ) : null}

          <Typography
            variant="h4"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {navbarTitle}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 0,
  },
}));
