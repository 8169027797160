import apiEndpoints from "./apiEndpoints";
import errorCodes from "./errorCodes";
import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from "../localStorage/localStorage";

const badResponse = {
  success: false,
  error: {
    message: "Server Error.",
  },
};

class apiServicesClient {
  constructor() {
    this.localStorage = {
      user: loadFromLocalStorage("user"),
      tokens: loadFromLocalStorage("tokens"),
    };

    window.addEventListener("userChanged", () => {
      this.localStorage.user = loadFromLocalStorage("user");
      this.localStorage.tokens = loadFromLocalStorage("tokens");
    });

    this.failedAccessTokenRefreshCount = 0;

    this.refreshAccessToken = async () => {
      console.log("---> Refreshing Access Token");

      if (this.failedAccessTokenRefreshCount > 2) {
        console.error("Failed to refresh the access token twice.");
        return false;
      }

      if (!this.localStorage.tokens?.refresh) {
        console.error("Local storage had no refresh token.");
        return false;
      }

      const requestBody = {
        refreshToken: this.localStorage.tokens.refresh,
      };

      const response = await fetch(apiEndpoints.user.refreshToken, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        console.log(responseData);
        this.localStorage.user = responseData.result.user;
        this.localStorage.tokens = responseData.result.tokens;
        saveToLocalStorage("user", this.localStorage.tokens);
        saveToLocalStorage("tokens", this.localStorage.tokens);
        this.failedAccessTokenRefreshCount = 0;
        console.log("Token refreshed successfully.");
      } else {
        console.log("Token refresh failed");
        console.log(response);
        this.failedAccessTokenRefreshCount++;
        return false;
      }

      return true;
    };

    this.parseResponse = async (response, retryFunction = undefined) => {
      const responseData = await response.json();

      // console.log(responseData);

      if (retryFunction === undefined) {
        return responseData;
      }

      if (
        response.status === 401 &&
        [10, 11].includes(responseData.error.errorCode)
      ) {
        if (await this.refreshAccessToken()) {
          return await retryFunction();
        }
      }

      return responseData;
    };

    this.user = {
      login: async (email, password) => {
        try {
          const requestBody = {
            email: email,
            password: password,
          };

          const response = await fetch(apiEndpoints.user.login, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response);
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      logout: async () => {
        try {
          const requestBody = {
            refreshToken: this.localStorage.tokens.refresh,
          };

          const response = await fetch(apiEndpoints.user.logout, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () => this.user.logout());
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      verifyAccountSetupToken: async (token) => {
        try {
          const requestBody = {
            token: token,
          };

          const response = await fetch(
            apiEndpoints.user.verifyAccountSetupToken,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response);
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      performAccountSetup: async (token, name, password) => {
        // try {
        const requestBody = {
          token: token,
          name: name,
          password: password,
        };
        console.log(JSON.stringify(requestBody));

        const response = await fetch(apiEndpoints.user.performAccountSetup, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        return await this.parseResponse(response);
        // } catch (error) {
        //   console.log(error);
        //   return false;
        // }
      },

      requestResetPassword: async (email) => {
        try {
          const requestBody = {
            email: email,
          };

          const response = await fetch(apiEndpoints.user.requestResetPassword, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response);
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      verifyResetPasswordToken: async (token) => {
        try {
          const requestBody = {
            token: token,
          };

          const response = await fetch(
            apiEndpoints.user.verifyResetPasswordToken,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response);
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      performResetPassword: async (token, newPassword) => {
        try {
          const requestBody = {
            token: token,
            newPassword: newPassword,
          };

          const response = await fetch(apiEndpoints.user.performResetPassword, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response);
        } catch (error) {
          console.log(error);
          return false;
        }
      },
    };

    this.patient = {
      addNew: async (patient) => {
        const requestBody = {
          patient: patient,
        };
        try {
          const response = await fetch(apiEndpoints.patient.addNew, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () =>
            this.patient.addNew(patient)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      updateImage: async (docId, formData) => {
        try {
          const response = await fetch(
            apiEndpoints.patient.updateImage.replace("{{docId}}", docId),
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: formData,
            }
          );

          return await this.parseResponse(response, () =>
            this.patient.update(docId, formData)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      update: async (docId, patientDetails) => {
        const requestBody = {
          patient: patientDetails,
        };
        try {
          const response = await fetch(
            apiEndpoints.patient.update.replace("{{docId}}", docId),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response, () =>
            this.patient.update(docId, patientDetails)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      delete: async (docId) => {
        try {
          const response = await fetch(
            apiEndpoints.patient.delete.replace("{{docId}}", docId),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patient.delete(docId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      getDetail: async (docId) => {
        try {
          const response = await fetch(
            apiEndpoints.patient.getDetail.replace("{{docId}}", docId),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patient.getDetail(docId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      search: async (searchQuery) => {
        try {
          console.log(
            apiEndpoints.patient.search.replace("{{searchQuery}}", searchQuery)
          );

          const response = await fetch(
            apiEndpoints.patient.search.replace("{{searchQuery}}", searchQuery),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patient.search(searchQuery)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      getAllUpdatedSince: async (timestamp) => {
        try {
          const response = await fetch(
            apiEndpoints.patient.getAllUpdatedSince.replace(
              "{{timestamp}}",
              timestamp
            ),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patient.getAllUpdatedSince(timestamp)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };

    this.patientDocument = {
      addNew: async (patientId, patientDocument) => {
        const requestBody = {
          patientId: patientId,
          patientDocument: patientDocument,
        };
        try {
          const response = await fetch(apiEndpoints.patientDocument.addNew, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () =>
            this.patientDocument.addNew(patientId, patientDocument)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      updateFile: async (docId, formData) => {
        try {
          const response = await fetch(
            apiEndpoints.patientDocument.updateFile.replace("{{docId}}", docId),
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: formData,
            }
          );

          return await this.parseResponse(response, () =>
            this.patientDocument.update(docId, formData)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      update: async (docId, patientDocumentDetails) => {
        const requestBody = {
          patientDocument: patientDocumentDetails,
        };
        try {
          const response = await fetch(
            apiEndpoints.patientDocument.update.replace("{{docId}}", docId),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response, () =>
            this.patientDocument.update(docId, patientDocumentDetails)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      delete: async (docId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientDocument.delete.replace("{{docId}}", docId),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientDocument.delete(docId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },

      getDetail: async (docId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientDocument.getDetail.replace("{{docId}}", docId),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientDocument.getDetail(docId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },

      ///////////////
      getAllForPatient: async (patientId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientDocument.getAllForPatient.replace(
              "{{patientId}}",
              patientId
            ),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientDocument.getAllForPatient(patientId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };

    this.point = {
      getAll: async () => {
        try {
          const response = await fetch(apiEndpoints.point.getAll, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
          });

          return await this.parseResponse(response, () => this.point.getAll());
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };

    this.patientLog = {
      addNew: async (newPatientLog) => {
        try {
          const response = await fetch(apiEndpoints.patientLog.addNew, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(newPatientLog),
          });

          return await this.parseResponse(response, () =>
            this.patientLog.addNew(newPatientLog)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      addImage: async (docId, formData) => {
        try {
          const response = await fetch(
            apiEndpoints.patientLog.addImage.replace("{{docId}}", docId),
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: formData,
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.addImage(docId, formData)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      deleteImage: async (docId, imageId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientLog.deleteImage
              .replace("{{docId}}", docId)
              .replace("{{imageId}}", imageId),
            {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.deleteImage(docId, imageId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      reorderImages: async (docId, newOrder) => {
        try {
          const requestBody = {
            newOrder: newOrder,
          };

          const response = await fetch(
            apiEndpoints.patientLog.reorderImages.replace("{{docId}}", docId),
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.reorderImages(docId, newOrder)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      updateImageNotes: async (docId, imageId, imageNotes) => {
        try {
          const requestBody = {
            imageNotes: imageNotes,
          };

          const response = await fetch(
            apiEndpoints.patientLog.updateImageNotes
              .replace("{{docId}}", docId)
              .replace("{{imageId}}", imageId),
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.updateImageNotes(docId, imageId, imageNotes)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      update: async (docId, updatedPatientLog) => {
        try {
          const requestBody = {
            patientLog: updatedPatientLog,
          };

          const response = await fetch(
            apiEndpoints.patientLog.update.replace("{{docId}}", docId),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
              body: JSON.stringify(requestBody),
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.update(docId, updatedPatientLog)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      delete: async (docId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientLog.delete.replace("{{docId}}", docId),
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.delete(docId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      getDetail: async (docId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientLog.getDetail.replace("{{docId}}", docId),
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.getDetail(docId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      getLatestLogForPatient: async (patientId) => {
        try {
          const response = await fetch(
            apiEndpoints.patientLog.getLatestLogForPatient.replace(
              "{{patientId}}",
              patientId
            ),
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${this.localStorage.tokens.access}`,
              },
            }
          );

          return await this.parseResponse(response, () =>
            this.patientLog.getLatestLogForPatient(patientId)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      search: async (searchParams) => {
        try {
          let endpoint = apiEndpoints.patientLog.search;
          let numQueryParamsAdded = 0;
          if (searchParams.patientId !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `patientId=${searchParams.patientId}`;
            numQueryParamsAdded++;
          }
          if (searchParams.pointId !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `pointId=${searchParams.pointId}`;
            numQueryParamsAdded++;
          }
          if (searchParams.startTimestamp !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `startTimestamp=${searchParams.startTimestamp}`;
            numQueryParamsAdded++;
          }
          if (searchParams.endTimestamp !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `endTimestamp=${searchParams.endTimestamp}`;
            numQueryParamsAdded++;
          }
          if (searchParams.notes !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `notes=${searchParams.notes}`;
            numQueryParamsAdded++;
          }
          if (searchParams.prescription !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `prescription=${searchParams.prescription}`;
            numQueryParamsAdded++;
          }
          if (searchParams.limit !== undefined) {
            if (numQueryParamsAdded > 0) {
              endpoint += "&";
            }
            endpoint += `limit=${searchParams.limit}`;
            numQueryParamsAdded++;
          }

          console.log(endpoint);

          const response = await fetch(endpoint, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
          });

          return await this.parseResponse(response, () =>
            this.patientLog.search(searchParams)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };
  }
}

const apiClient = new apiServicesClient();

export default apiClient;
