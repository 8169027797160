import { useState } from "react";
import { Box, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export default function PasswordInput({
  label,
  value,
  onChange,
  error,
  helperText,
}) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      width={300}
      mb={2}
    >
      <TextField
        className={classes.inputTextField}
        type={showPassword ? "text" : "password"}
        label={label ? label : "Password"}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                variant="contained"
                onClick={() => setShowPassword(!showPassword)}
              >
                <VisibilityIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  inputTextField: {
    width: 300,
  },
}));
