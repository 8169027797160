function objectDeepEqual(obj1, obj2) {
  for (const key in obj1) {
    if (obj2[key] === undefined || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  for (const key in obj2) {
    if (obj1[key] === undefined || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

function isMongoObjectId(str) {
  return str.match(/^[0-9a-fA-F]{24}$/);
}

function getPathnameWithoutParams(pathname) {
  const pathnameParts = pathname.split("/");
  let pathnameWithoutParams = "";
  for (const pathnamePart of pathnameParts) {
    if (isMongoObjectId(pathnamePart)) {
      break;
    }
    pathnameWithoutParams += pathnamePart + "/";
  }
  // remove the last "/"
  pathnameWithoutParams = pathnameWithoutParams.substring(
    0,
    pathnameWithoutParams.length - 1
  );

  return pathnameWithoutParams;
}

export { objectDeepEqual, getPathnameWithoutParams };
