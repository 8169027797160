const localStorageKeys = {
  lastState: {
    homePage: "homePageLastState", // done
    searchPatientsPage: "searchPatientsPageLastState", // done
    searchPatientLogsPage: "searchPatientLogsPageLastState", // done
    patientDetailsPage: "patientDetailsPageLastState", // done
    allLogsOfPatientPage: "allLogsOfPatientPageLastState", // done
    allDocumentsOfPatientPage: "allDocumentsOfPatientPageLastState", // done
  },
};

export default localStorageKeys;
