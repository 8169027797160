// Done
import { useState, useEffect, useContext } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Container,
  Typography,
  Tooltip,
  Avatar,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

import PatientLogListItem from "../components/PatientLogListItem";
import userContext from "../context/userContext";
import apiServicesClient from "../api/apiServices";
import {
  parsePatientObjectForFrontend,
  parsePatientLogObjectForJs,
} from "../api/apiUtils";
import MuiTableWithSort from "../components/MuiTableWithSort";
import SinglePatientDisplayer from "../components/SinglePatientDisplayer";
import MuiPatientsTopNavigation from "../components/MuiPatientsTopNavigation";
import PatientLogListHeader from "../components/PatientLogListHeader";
import SortingKeySelect from "../components/SortingKeySelect";
import localStorageKeys from "../localStorage/keys";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../localStorage/localStorage";
import stateContext from "../context/stateContext";

const AllLogsOfPatient = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const {
    allLogsOfPatientPageErrorText,
    setAllLogsOfPatientPageErrorText,
    allLogsOfPatientPagePatientDetails,
    setAllLogsOfPatientPagePatientDetails,
    allLogsOfPatientPagePatientLogs,
    setAllLogsOfPatientPagePatientLogs,
    allLogsOfPatientPageSortingKey,
    setAllLogsOfPatientPageSortingKey,
  } = useContext(stateContext);
  const { patientId } = useParams();

  const [isLoadingPatientDetails, setIsLoadingPatientDetails] = useState(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [areAllLogsLoaded, setAreAllLogsLoaded] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [getDetailsErrorTitle, setGetDetailsErrorTitle] = useState("");
  const [getDetailsErrorSubtitle, setGetDetailsErrorSubtitle] = useState("");

  const sortPatientLogs = (inputPatientLogs, inputSortingKey) => {
    switch (inputSortingKey) {
      case "Point": {
        inputPatientLogs.sort((a, b) => {
          if (a.point.name > b.point.name) {
            return 1;
          }
          if (a.point.name < b.point.name) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Point Desc": {
        inputPatientLogs.sort((a, b) => {
          if (a.point.name > b.point.name) {
            return -1;
          }
          if (a.point.name < b.point.name) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "Date & Time": {
        inputPatientLogs.sort((a, b) => {
          const d1 = new Date(a.logDatetime);
          const d2 = new Date(b.logDatetime);
          return d1 - d2;
        });
        break;
      }
      case "Date & Time Desc": {
        inputPatientLogs.sort((a, b) => {
          const d1 = new Date(a.logDatetime);
          const d2 = new Date(b.logDatetime);
          return d2 - d1;
        });
        break;
      }
      case "Notes": {
        inputPatientLogs.sort((a, b) => {
          if (a.notes > b.notes) {
            return 1;
          }
          if (a.notes < b.notes) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Notes Desc": {
        inputPatientLogs.sort((a, b) => {
          if (a.notes > b.notes) {
            return -1;
          }
          if (a.notes < b.notes) {
            return 1;
          }
          return 0;
        });
        break;
      }
      default: {
      }
    }

    return inputPatientLogs;
  };

  const getPatientDetailsFromApi = async () => {
    setIsLoadingPatientDetails(true);

    const response = await apiServicesClient.patient.getDetail(patientId);

    if (!response.success) {
      if (response.error.validationErrors) {
        toast("Invalid patient ID."); // since there is only one query param that could be invalid
      } else {
        toast(response.error.message);
      }

      setIsLoadingLogs(false);
      return;
    }

    setAllLogsOfPatientPagePatientDetails(
      parsePatientObjectForFrontend(response.result.patient)
    );
    setIsLoadingPatientDetails(false);
  };

  const getPatientLogsForLimitFromApi = async (limit, reset = false) => {
    setIsLoadingLogs(true);

    const searchParams = {
      patientId: patientId,
      limit: limit,
    };

    if (allLogsOfPatientPagePatientLogs.length !== 0 && !reset) {
      searchParams.endTimestamp =
        allLogsOfPatientPagePatientLogs[
          allLogsOfPatientPagePatientLogs.length - 1
        ].logDatetime.getTime() - 1;
    }

    const response = await apiServicesClient.patientLog.search(searchParams);

    if (!response.success) {
      if (response.error.validationErrors) {
        toast("Invalid patient ID."); // since there is only one query param that could be invalid
      } else {
        toast(response.error.message);
      }

      setIsLoadingLogs(false);
      setIsLoadingFirstTime(false);
      return;
    }

    if (response.result.patientLogs.length === 0) {
      toast("All logs loaded.");
      setAreAllLogsLoaded(true);
      setIsLoadingLogs(false);
      setIsLoadingFirstTime(false);
      return;
    }

    const newPatientLogs = response.result.patientLogs.map((patientLog) =>
      parsePatientLogObjectForJs(patientLog)
    );

    newPatientLogs.sort((a, b) => b.logDatetime - a.logDatetime);

    if (reset) {
      setAllLogsOfPatientPagePatientLogs(
        sortPatientLogs(newPatientLogs, allLogsOfPatientPageSortingKey)
      );
      setAreAllLogsLoaded(false);
    } else {
      let updatedPatientLogs = [...allLogsOfPatientPagePatientLogs];
      updatedPatientLogs = updatedPatientLogs.concat(newPatientLogs);

      setAllLogsOfPatientPagePatientLogs(
        sortPatientLogs(updatedPatientLogs, allLogsOfPatientPageSortingKey)
      );
    }

    setIsLoadingLogs(false);
    setIsLoadingFirstTime(false);
  };

  const handleLoadMoreButtonClick = (event) => {
    getPatientLogsForLimitFromApi(30);
  };

  useEffect(() => {
    document.title = "All Logs Of Patient";

    if (
      allLogsOfPatientPagePatientDetails === null ||
      allLogsOfPatientPagePatientDetails._id !== patientId
    ) {
      getPatientDetailsFromApi();
      getPatientLogsForLimitFromApi(30);
    }
  }, []);

  useEffect(() => {
    let updatedPatientLogs = [...allLogsOfPatientPagePatientLogs];
    updatedPatientLogs = sortPatientLogs(
      updatedPatientLogs,
      allLogsOfPatientPageSortingKey
    );
    setAllLogsOfPatientPagePatientLogs(updatedPatientLogs);
  }, [allLogsOfPatientPageSortingKey]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoadingPatientDetails) {
    return (
      <Container maxWidth="md" className={classes.container}>
        <MuiPatientsTopNavigation patientId={patientId} />
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={3}
        >
          <Box mr={2}>
            <Skeleton width={50} height={50} circle={true} inline={true} />
          </Box>
          <Skeleton width={250} height={32} />
        </Box>
      </Container>
    );
  }

  if (getDetailsErrorTitle !== "") {
    return (
      <div className={classes.allLogsOfPatientPagePatientDetailsPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Typography color="error" variant="h3">
            {getDetailsErrorTitle}
          </Typography>
          <Typography color="error" variant="p">
            {getDetailsErrorSubtitle}
          </Typography>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.allLogsOfPatientPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <MuiPatientsTopNavigation patientId={patientId} />

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box width="80%">
            <SinglePatientDisplayer
              patient={allLogsOfPatientPagePatientDetails}
              mb={0}
            />
          </Box>

          <IconButton
            color="warning"
            onClick={() => {
              setIsLoadingFirstTime(true);
              getPatientLogsForLimitFromApi(30, true);
            }}
            disabled={isLoadingFirstTime}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {allLogsOfPatientPageErrorText !== "" ? (
            <Box mb={2}>
              <Typography variant="h6" color="error">
                {allLogsOfPatientPageErrorText}
              </Typography>
            </Box>
          ) : allLogsOfPatientPagePatientLogs.length ===
            0 ? null : window.innerWidth >= 700 ? (
            <PatientLogListHeader
              sortingKey={allLogsOfPatientPageSortingKey}
              setSortingKey={setAllLogsOfPatientPageSortingKey}
              hideAvatarMargin={true}
            />
          ) : (
            <SortingKeySelect
              sortingKey={allLogsOfPatientPageSortingKey}
              setSortingKey={setAllLogsOfPatientPageSortingKey}
              allSortingKeys={[
                "Date & Time",
                "Point",
                "Notes",
                "Date & Time Desc",
                "Point Desc",
                "Notes Desc",
              ]}
            />
          )}

          {allLogsOfPatientPagePatientLogs.map((patientLog, index) => (
            <PatientLogListItem
              patientLogDetails={patientLog}
              index={index}
              key={index}
            />
          ))}
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mb={2}
        >
          <Tooltip title={areAllLogsLoaded ? "All logs have been loaded." : ""}>
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled={isLoadingLogs || areAllLogsLoaded}
                onClick={handleLoadMoreButtonClick}
              >
                {isLoadingLogs ? (
                  <BeatLoader color="#42a5f5" loading={true} size={10} />
                ) : (
                  "Load More"
                )}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Container>
    </div>
  );
};

export default AllLogsOfPatient;

const useStyles = makeStyles((theme) => ({
  allLogsOfPatientPageContainer: {
    width: "100%",
    minHeight: "100vh",
    // backgroundImage: gradients.allLogsOfPatientPagePatientDetailsPageContainer,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
}));
