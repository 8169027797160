// Done
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function PatientLogListItemWithAvatarAndName({
  patientLogDetails,
  index,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleListItemClick = (event) => {
    navigate(`/patient-logs/${patientLogDetails._id}`);
  };

  return (
    <Box
      className={classes.listItemContainer}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor={index % 2 === 0 ? "#EEEEEE" : "#DEDEDE"}
      borderRadius={1}
      p={1}
      mb={2}
      onClick={handleListItemClick}
    >
      <Box mr={1}>
        <Avatar
          alt={patientLogDetails?.patient?.name}
          src={patientLogDetails?.patient?.avatarImageUrl}
          sx={{ width: 40, height: 40 }}
        />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Typography noWrap>{patientLogDetails.patient.name}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography>
            {new Date(patientLogDetails.logDatetime).toString().slice(0, 24)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography noWrap>{patientLogDetails.notes}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#CCC",
    },
  },
}));
