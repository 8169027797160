import { IconButton, Typography, Checkbox, Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Sort as SortIcon,
} from "@mui/icons-material";

export default function PatientListHeader({ sortingKey, setSortingKey }) {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      p={1}
      mb={2}
    >
      <Box>
        <Box mr={2}>
          <div style={{ width: 40, height: 40 }} />
        </Box>
      </Box>

      <Grid container spacing={1}>
        <Grid className={classes.gridItem} item xs={12} sm={5}>
          <Typography
            className={classes.listHeaderCell}
            variant="h5"
            color="primary"
          >
            Patient Name
          </Typography>
          <IconButton
            onClick={() => {
              if (sortingKey === "Name Desc") {
                setSortingKey("Name");
              } else {
                setSortingKey("Name Desc");
              }
            }}
          >
            {sortingKey === "Name" ? (
              <ArrowUpwardIcon />
            ) : sortingKey === "Name Desc" ? (
              <ArrowDownwardIcon />
            ) : (
              <SortIcon />
            )}
          </IconButton>
        </Grid>

        <Grid className={classes.gridItem} item xs={12} sm={4}>
          <Typography
            className={classes.listHeaderCell}
            variant="h5"
            color="primary"
          >
            Last Seen Point
          </Typography>
          <IconButton
            onClick={() => {
              if (sortingKey === "Last Seen Point Desc") {
                setSortingKey("Last Seen Point");
              } else {
                setSortingKey("Last Seen Point Desc");
              }
            }}
          >
            {sortingKey === "Last Seen Point" ? (
              <ArrowUpwardIcon />
            ) : sortingKey === "Last Seen Point Desc" ? (
              <ArrowDownwardIcon />
            ) : (
              <SortIcon />
            )}
          </IconButton>
        </Grid>

        <Grid className={classes.gridItem} item xs={4} sm={3}>
          <Typography
            className={classes.listHeaderCell}
            variant="h5"
            color="primary"
          >
            Last Seen
          </Typography>
          <IconButton
            onClick={() => {
              if (sortingKey === "Last Seen Desc") {
                setSortingKey("Last Seen");
              } else {
                setSortingKey("Last Seen Desc");
              }
            }}
          >
            {sortingKey === "Last Seen" ? (
              <ArrowUpwardIcon />
            ) : sortingKey === "Last Seen Desc" ? (
              <ArrowDownwardIcon />
            ) : (
              <SortIcon />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  containerMain: {
    width: "100vw",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  pageHeading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  controlsContainer: {
    width: "64%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "2vh",
    marginBottom: "2vh",
  },
  controlsSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listHeaderContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  listHeaderCell: {
    borderRadius: 5,
    "&&": {
      marginRight: 5,
    },
    // "&:hover": {
    //   cursor: "pointer",
    //   backgroundColor: "#BBBBBB",
    // },
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));
