import { useState, useEffect, useContext } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { Button, Box, Container, Typography, IconButton } from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "react-loading-skeleton";

import userContext from "../context/userContext";
import apiServicesClient from "../api/apiServices";
import SinglePatientDisplayer from "../components/SinglePatientDisplayer";
import PatientDocumentListHeader from "../components/PatientDocumentListHeader";
import SortingKeySelect from "../components/SortingKeySelect";
import {
  parsePatientObjectForFrontend,
  parsePatientDocumentObjectForFrontend,
} from "../api/apiUtils";
import PatientDocumentListItem from "../components/PatientDocumentListItem";
import MuiPatientsTopNavigation from "../components/MuiPatientsTopNavigation";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../localStorage/localStorage";
import localStorageKeys from "../localStorage/keys";
import stateContext from "../context/stateContext";

const AllDocumentsOfPatient = () => {
  const classes = useStyles();
  const { user } = useContext(userContext);
  const {
    allDocsOfPatientPageErrorText,
    setAllDocsOfPatientPageErrorText,
    allDocsOfPatientPagePatientDetails,
    setAllDocsOfPatientPagePatientDetails,
    allDocsOfPatientPagePatientDocs,
    setAllDocsOfPatientPagePatientDocs,
    allDocsOfPatientPageSortingKey,
    setAllDocsOfPatientPageSortingKey,
  } = useContext(stateContext);
  const navigate = useNavigate();
  const { patientId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [getDetailsErrorTitle, setGetDetailsErrorTitle] = useState("");
  const [getDetailsErrorSubtitle, setGetDetailsErrorSubtitle] = useState("");

  const sortPatientDocuments = (inputPatientDocuments, inputSortingKey) => {
    switch (inputSortingKey) {
      case "Type": {
        inputPatientDocuments.sort((a, b) => {
          if (a.documentType > b.documentType) {
            return 1;
          }
          if (a.documentType < b.documentType) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Type Desc": {
        inputPatientDocuments.sort((a, b) => {
          if (a.documentType > b.documentType) {
            return -1;
          }
          if (a.documentType < b.documentType) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "Title": {
        inputPatientDocuments.sort((a, b) => {
          if (a.documentTitle > b.documentTitle) {
            return 1;
          }
          if (a.documentTitle < b.documentTitle) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Title Desc": {
        inputPatientDocuments.sort((a, b) => {
          if (a.documentTitle > b.documentTitle) {
            return -1;
          }
          if (a.documentTitle < b.documentTitle) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "Notes": {
        inputPatientDocuments.sort((a, b) => {
          if (a.documentNotes > b.documentNotes) {
            return 1;
          }
          if (a.documentNotes < b.documentNotes) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Notes Desc": {
        inputPatientDocuments.sort((a, b) => {
          if (a.documentNotes > b.documentNotes) {
            return -1;
          }
          if (a.documentNotes < b.documentNotes) {
            return 1;
          }
          return 0;
        });
        break;
      }
      default: {
      }
    }

    return inputPatientDocuments;
  };

  const getPatientDocumentsFromApi = async () => {
    console.log("All Docs of Patient: Calling API.");
    setIsLoading(true);

    const response = await apiServicesClient.patientDocument.getAllForPatient(
      patientId
    );

    if (!response.success) {
      console.log(response);
      if (response.error.validationErrors) {
        setGetDetailsErrorTitle("Invalid Patient ID");
        setGetDetailsErrorSubtitle(
          "It looks like you have entered an invalid ID for a Patient."
        );
      } else {
        setGetDetailsErrorTitle("Failed to fetch Documents of Patient");
        setGetDetailsErrorSubtitle("Refresh the page to try again.");
      }

      setIsLoading(false);
      setIsLoadingFirstTime(false);
      return;
    }

    const responsePatientDetails = parsePatientObjectForFrontend(
      response.result.patient
    );

    const responsePatientDocuments = response.result.patientDocuments.map(
      (patientDocument) =>
        parsePatientDocumentObjectForFrontend(patientDocument)
    );
    if (responsePatientDocuments.length === 0) {
      setAllDocsOfPatientPageErrorText("No documents to show.");
    } else {
      setAllDocsOfPatientPageErrorText("");
    }

    setAllDocsOfPatientPagePatientDetails(responsePatientDetails);
    setAllDocsOfPatientPagePatientDocs(
      sortPatientDocuments(
        responsePatientDocuments,
        allDocsOfPatientPageSortingKey
      )
    );

    setIsLoading(false);
    setIsLoadingFirstTime(false);
  };

  useEffect(() => {
    document.title = "All Documents of Patient";

    if (
      allDocsOfPatientPagePatientDetails === null ||
      allDocsOfPatientPagePatientDetails._id !== patientId
    ) {
      getPatientDocumentsFromApi();
    }
  }, []);

  useEffect(() => {
    let updatedPatientDocuments = [...allDocsOfPatientPagePatientDocs];
    updatedPatientDocuments = sortPatientDocuments(
      updatedPatientDocuments,
      allDocsOfPatientPageSortingKey
    );
    setAllDocsOfPatientPagePatientDocs(updatedPatientDocuments);
  }, [allDocsOfPatientPageSortingKey]);

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoading) {
    return (
      <div className={classes.allDocsOfPatientPagePatientDetailsPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <MuiPatientsTopNavigation patientId={patientId} />
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            mb={2}
          >
            <Box width="15%" mr={2}>
              <Skeleton circle style={{ height: 50, width: 50 }} />
            </Box>
            <Box width="70%">
              <Skeleton height={50} />
            </Box>
            <Box width="15%" ml={2}></Box>
          </Box>

          <Skeleton style={{ height: 50, marginBottom: 20 }} count={5} />
        </Container>
      </div>
    );
  }

  if (getDetailsErrorTitle !== "") {
    return (
      <div className={classes.allDocsOfPatientPagePatientDetailsPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Typography color="error" variant="h3">
            {getDetailsErrorTitle}
          </Typography>
          <Typography color="error" variant="p">
            {getDetailsErrorSubtitle}
          </Typography>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.allDocumentsOfPatientPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <MuiPatientsTopNavigation patientId={patientId} />

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box width="80%">
            <SinglePatientDisplayer
              patient={allDocsOfPatientPagePatientDetails}
              mb={0}
            />
          </Box>

          <IconButton
            color="warning"
            onClick={() => {
              setIsLoadingFirstTime(true);
              getPatientDocumentsFromApi();
            }}
            disabled={isLoadingFirstTime}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {allDocsOfPatientPageErrorText !== "" ? (
            <Box mb={2}>
              <Typography variant="h6" color="error">
                {allDocsOfPatientPageErrorText}
              </Typography>
            </Box>
          ) : allDocsOfPatientPagePatientDocs.length ===
            0 ? null : window.innerWidth >= 700 ? (
            <PatientDocumentListHeader
              sortingKey={allDocsOfPatientPageSortingKey}
              setSortingKey={setAllDocsOfPatientPageSortingKey}
            />
          ) : (
            <SortingKeySelect
              sortingKey={allDocsOfPatientPageSortingKey}
              setSortingKey={setAllDocsOfPatientPageSortingKey}
              allSortingKeys={[
                "Type",
                "Title",
                "Notes",
                "Type Desc",
                "Title Desc",
                "Notes Desc",
              ]}
            />
          )}

          {allDocsOfPatientPagePatientDocs.map((patientDocument, index) => (
            <PatientDocumentListItem
              patientDocumentDetails={patientDocument}
              index={index}
              key={index}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default AllDocumentsOfPatient;

const useStyles = makeStyles((theme) => ({
  allDocumentsOfPatientPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
}));
