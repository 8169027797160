import { useState, useEffect, useContext, useRef } from "react";
import {
  useParams,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";

import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import { parsePatientDocumentObjectForFrontend } from "../api/apiUtils";
import SinglePatientDisplayer from "../components/SinglePatientDisplayer";
import CustomDatePicker from "../components/CustomDatePicker";
import { patientDocumentDeleteAllowedTimeInHours } from "../config/appConfig";
import ConfirmDialog from "../components/ConfirmDialog";

// const windowWidth = window.innerWidth;
// const windowHeight = window.innerHeight;
// const pdfDisplayDimension = Math.min(windowWidth, windowHeight) * 0.75;

export default function PatientDocumentDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const { user } = useContext(userContext);
  const { patientDocumentId } = useParams();

  const fileInputRef = useRef(null);

  const [originalPatientDocument, setOriginalPatientDocument] = useState(null);

  const [uploadedPdfFile, setUploadedPdfFile] = useState(null);
  const [uploadedPdfFileData, setUploadedPdfFileData] = useState(null);
  const [uploadedPdfFileBlobUrl, setUploadedPdfFileBlobUrl] = useState(null);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentIssueDate, setDocumentIssueDate] = useState(null);
  const [documentNotes, setDocumentNotes] = useState("");

  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [getDetailsErrorTitle, setGetDetailsErrorTitle] = useState("");
  const [getDetailsErrorSubtitle, setGetDetailsErrorSubtitle] = useState("");
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "application/pdf") {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileData = e.target.result;
        setUploadedPdfFile(uploadedFile);
        setUploadedPdfFileData(fileData);
        setUploadedPdfFileBlobUrl(URL.createObjectURL(uploadedFile));
      };
      fileReader.readAsDataURL(uploadedFile);
      setHasUnsavedChanges(true);
    }
  };

  const handleUploadedFilenameButtonClick = (event) => {
    window.open(uploadedPdfFileBlobUrl, "_blank", "width=600, height=400");
  };

  const loadStateFromObject = (patientDocument) => {
    setSelectedPatient(patientDocument.patient);
    setDocumentType(patientDocument.documentType);
    setDocumentTitle(patientDocument.documentTitle);
    setDocumentIssueDate(patientDocument.documentIssueDate);
    setDocumentNotes(patientDocument.documentNotes);

    if (patientDocument.documentFileUrl !== undefined) {
      setUploadedPdfFile({
        name: `${patientDocument.patient.name} - ${patientDocument.documentTitle}.pdf`,
        original: true,
      });
      setUploadedPdfFileBlobUrl(patientDocument.documentFileUrl);
    }

    setCanBeDeleted(
      Math.abs(new Date() - patientDocument?.createdAt) / 36e5 <
        patientDocumentDeleteAllowedTimeInHours
    );

    setUploadedPdfFileData(null);
  };

  const getPatientDocumentDetailsFromAPI = async () => {
    setIsLoading(true);

    const response = await apiClient.patientDocument.getDetail(
      patientDocumentId
    );
    if (!response.success) {
      console.log(response);
      if (response.error.validationErrors) {
        setGetDetailsErrorTitle("Invalid Patient Document URL.");
        setGetDetailsErrorSubtitle(
          "It looks like you have entered an invalid URL for a Patient Document."
        );
      } else {
        setGetDetailsErrorTitle("Failed to fetch Patient Document");
        setGetDetailsErrorSubtitle("Refresh the page to try again.");
      }

      setIsLoading(false);
      return;
    }

    if (response.result.patientDocument === null) {
      setGetDetailsErrorTitle("Patient Document Not Found");
      setGetDetailsErrorSubtitle(
        "It looks like you have entered the URL to a Patient Document that does not exist."
      );
      setIsLoading(false);
      return;
    }

    response.result.patientDocument = parsePatientDocumentObjectForFrontend(
      response.result.patientDocument
    );

    setOriginalPatientDocument(response.result.patientDocument);
    loadStateFromObject(response.result.patientDocument);
    setIsLoading(false);
  };

  const handleSaveButtonClick = async (event) => {
    event.preventDefault();

    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setIsUploading(true);

    const toUpdateDetails =
      documentTitle !== originalPatientDocument.documentTitle ||
      documentType !== originalPatientDocument.documentType ||
      documentNotes !== originalPatientDocument.documentNotes ||
      documentIssueDate !== originalPatientDocument.documentIssueDate;
    const toUpdateFile = !uploadedPdfFile.original;

    let updatedPatientDocumentObject = originalPatientDocument;

    // File update goes first since we have to get the updated patient document
    if (toUpdateFile) {
      const formData = new FormData();
      formData.append("file", uploadedPdfFile);
      const updateFileResponse = await apiClient.patientDocument.updateFile(
        patientDocumentId,
        formData
      );
      if (!updateFileResponse.success) {
        toast(updateFileResponse.error.message);
        console.log(updateFileResponse);
        setIsUploading(false);
        return;
      }

      updatedPatientDocumentObject = parsePatientDocumentObjectForFrontend(
        updateFileResponse.result.patientDocument
      );
    }

    if (toUpdateDetails) {
      const updatedPatientDocument = {
        documentTitle: documentTitle,
        documentType: documentType,
        ...(documentIssueDate !== null
          ? { documentIssueDate: documentIssueDate.toISOString() }
          : {}),
        ...(documentNotes !== null ? { documentNotes: documentNotes } : {}),
      };

      const updateDetailsResponse = await apiClient.patientDocument.update(
        patientDocumentId,
        updatedPatientDocument
      );
      if (!updateDetailsResponse.success) {
        toast(updateDetailsResponse.error.message);
        console.log(updateDetailsResponse);
        setIsUploading(false);
        return;
      }

      updatedPatientDocumentObject = parsePatientDocumentObjectForFrontend(
        updateDetailsResponse.result.patientDocument
      );
    }

    if (toUpdateFile || toUpdateDetails) {
      setOriginalPatientDocument(updatedPatientDocumentObject);
      loadStateFromObject(updatedPatientDocumentObject);
    }

    setIsUploading(false);
    setIsInEditMode(false);
  };

  const handleDiscardButtonClick = (event) => {
    event.preventDefault();

    if (!hasUnsavedChanges) {
      setIsInEditMode(false);
      return;
    }

    loadStateFromObject(originalPatientDocument);

    setIsInEditMode(false);
    setHasUnsavedChanges(false);
  };

  const handleEditButtonClick = (event) => {
    event.preventDefault();

    setIsInEditMode(true);
  };

  const deleteObject = async () => {
    setIsUploading(true);

    const response = await apiClient.patientDocument.delete(patientDocumentId);
    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsUploading(false);
      return;
    }

    toast("Patient Document deleted successfully.");
    setIsUploading(false);

    setTimeout(() => {
      if (doesAnyHistoryEntryExist) {
        navigate(-1);
      } else {
        navigate("/");
      }
    }, 2000);
  };

  const handleDeleteButtonClick = async (event) => {
    setDeleteConfirmDialogOpen(true);
  };

  useEffect(() => {
    document.title = "Patient Document Details";
    getPatientDocumentDetailsFromAPI();
  }, []);

  useEffect(() => {
    setValidationErrors(
      validators.patientDocuments.addNew(
        selectedPatient?._id,
        documentType,
        documentTitle,
        documentIssueDate,
        documentNotes,
        uploadedPdfFile
      )
    );

    setHasUnsavedChanges(
      documentTitle !== originalPatientDocument?.documentTitle ||
        documentType !== originalPatientDocument?.documentType ||
        documentNotes !== originalPatientDocument?.documentNotes ||
        documentIssueDate !== originalPatientDocument?.documentIssueDate ||
        !uploadedPdfFile?.original
    );
  }, [
    selectedPatient,
    documentType,
    documentTitle,
    documentIssueDate,
    documentNotes,
    uploadedPdfFile,
  ]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoading) {
    return (
      <div className={classes.createPatientLogPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Box mb={8} />
          <Skeleton
            width={50}
            height={50}
            circle={true}
            className={classes.skeleton}
          />
          <Skeleton height={35} className={classes.skeleton} />
          <Skeleton height={35} className={classes.skeleton} />
          <Skeleton height={35} className={classes.skeleton} />
          <Skeleton height={100} className={classes.skeleton} count={1} />
          <Skeleton height={35} className={classes.skeleton} />
        </Container>
      </div>
    );
  }

  if (getDetailsErrorTitle !== "") {
    return (
      <div className={classes.createPatientLogPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Typography color="error" variant="h3">
            {getDetailsErrorTitle}
          </Typography>
          <Typography color="error" variant="p">
            {getDetailsErrorSubtitle}
          </Typography>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.createPatientLogPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".pdf"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <ConfirmDialog
          open={deleteConfirmDialogOpen}
          onClose={() => setDeleteConfirmDialogOpen(false)}
          title="Delete Patient Document?"
          contentText="Are you sure you want to delete this Patient Document? A deleted Patient Document cannot be recovered."
          onAgree={() => deleteObject()}
          onDisagree={() => setDeleteConfirmDialogOpen(false)}
          color="error"
        />

        {isInEditMode ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            {isUploading ? (
              <Box mr={2}>
                <ClipLoader color={"#00FF00"} loading={true} size={25} />
              </Box>
            ) : null}
            <Box mr={2}>
              <Tooltip
                title={!hasUnsavedChanges ? "No changes have been made." : ""}
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveButtonClick}
                    disabled={
                      isUploading ||
                      !hasUnsavedChanges ||
                      (displayValidationErrors &&
                        Object.keys(validationErrors).length > 0)
                    }
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            </Box>

            <Box mr={2}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDiscardButtonClick}
                disabled={isUploading}
              >
                {hasUnsavedChanges ? "Discard" : "Stop Editing"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditButtonClick}
              >
                Edit
              </Button>
            </Box>

            <Box mr={2}>
              <Tooltip
                title={
                  canBeDeleted
                    ? ""
                    : `A log can only be deleted up till ${patientDocumentDeleteAllowedTimeInHours} hours after creation.`
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteButtonClick}
                    disabled={!canBeDeleted}
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        )}

        <SinglePatientDisplayer patient={selectedPatient} />

        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Document Type"
            value={documentType}
            onChange={(e) => {
              setDocumentType(e.target.value);
              setHasUnsavedChanges(true);
            }}
            disabled={isUploading || !isInEditMode}
            error={
              displayValidationErrors &&
              validationErrors.documentType !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.documentType
            }
          />
        </Box>
        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Document Title"
            value={documentTitle}
            onChange={(e) => {
              setDocumentTitle(e.target.value);
              setHasUnsavedChanges(true);
            }}
            disabled={isUploading || !isInEditMode}
            error={
              displayValidationErrors &&
              validationErrors.documentTitle !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.documentTitle
            }
          />
        </Box>

        <CustomDatePicker
          label="Document Issue Date"
          selected={documentIssueDate}
          onChange={setDocumentIssueDate}
          onFocus={() => {}}
          disabled={isUploading || !isInEditMode}
          showTimeSelect={true}
          handleClearButtonClick={() => {
            setDocumentIssueDate(null);
            setHasUnsavedChanges(true);
          }}
          errorText={
            displayValidationErrors && validationErrors.documentIssueDate
          }
        />

        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Document Notes"
            multiline
            rows={3}
            value={documentNotes}
            onChange={(e) => {
              setDocumentNotes(e.target.value);
              setHasUnsavedChanges(true);
            }}
            disabled={isUploading || !isInEditMode}
            error={
              displayValidationErrors &&
              validationErrors.documentNotes !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.documentNotes
            }
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          {uploadedPdfFile !== null ? (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Box maxWidth="45%">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleUploadedFilenameButtonClick}
                  sx={{ maxWidth: "100%" }}
                >
                  <Typography variant="p" noWrap>
                    {uploadedPdfFile.name}
                  </Typography>
                </Button>
              </Box>

              <Button
                color="primary"
                variant={isInEditMode ? "contained" : "outlined"}
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading || !isInEditMode}
              >
                Change File
              </Button>
            </Box>
          ) : (
            <Button
              color={
                displayValidationErrors && validationErrors.documentFile
                  ? "error"
                  : "primary"
              }
              variant="outlined"
              onClick={() => fileInputRef.current.click()}
              disabled={isUploading || !isInEditMode}
            >
              No File Chosen
            </Button>
          )}
        </Box>

        {originalPatientDocument?.updatedBy?.name ? (
          <Box mt={2}>
            <Typography fontSize={12} variant="p">
              {"Patient Document last updated by "}
            </Typography>

            <Typography fontSize={12} variant="p" color="primary">
              {`${originalPatientDocument.updatedBy.name} `}
            </Typography>

            <Typography fontSize={12} variant="p">
              {"at "}
            </Typography>

            <Typography fontSize={12} variant="p" color="primary">
              {new Date(originalPatientDocument?.updatedAt)
                .toString()
                .slice(0, 24)}
            </Typography>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  createPatientLogPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: "100%",
  },
  skeleton: {
    marginBottom: 20,
  },
}));
