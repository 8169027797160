import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function CustomTextField({
  label,
  type,
  disabled,
  value,
  onChange,
  error,
  helperText,
  mb,
}) {
  const classes = useStyles();

  return (
    <Box width="100%" mb={mb ? mb : 2}>
      <TextField
        className={classes.inputTextField}
        label={label}
        type={type}
        disabled={disabled}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  inputTextField: {
    width: "100%",
  },
}));
