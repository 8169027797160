import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Container, Box, TextField, Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

import userContext from "../context/userContext";
import PatientSelect from "../components/PatientSelect";
import PointSelectNoChange from "../components/PointSelectNoChange";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import MuiPatientsTopNavigation from "../components/MuiPatientsTopNavigation";

export default function CreatePatientLog() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const { patientId } = useParams();

  const fileInputRef = useRef(null);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const [notes, setNotes] = useState("");
  const [prescription, setPrescription] = useState("");
  const [previousSelectedImageIndex, setPreviousSelectedImageIndex] =
    useState(-1);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [imageNotes, setImageNotes] = useState([]);
  const [selectedImageNotes, setSelectedImageNotes] = useState("");
  const [images, setImages] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const validateAllFields = (
    updatedImageNotes = null,
    changeSelectedImageIndex = true
  ) => {
    const newValidationErrors = validators.patientLogs.addNew(
      selectedPatient,
      selectedPoint,
      notes,
      updatedImageNotes === null ? imageNotes : updatedImageNotes
    );
    setValidationErrors(newValidationErrors);

    if (newValidationErrors.imageNotes !== undefined) {
      let errorImageIndex = -1;
      for (let i = 0; i < newValidationErrors.imageNotes.length; i++) {
        if (newValidationErrors.imageNotes[i] !== "") {
          errorImageIndex = i;
          break;
        }
      }

      if (changeSelectedImageIndex) {
        setSelectedImageIndex(errorImageIndex);
      }
    }

    return Object.keys(newValidationErrors).length === 0;
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      toast("Invalid file.");
      return;
    }

    if (uploadedFile.type !== "image/jpeg") {
      toast("Only .jpeg files can be uploaded for Patient Documents.");
      return;
    }

    if (uploadedFile.size > 5_242_880) {
      toast("File size cannot be larger than 5 MB.");
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const file = e.target.result;

      const updatedImages = [...images];
      updatedImages.push({
        filePath: uploadedFile,
        url: file,
      });

      const updatedImageNotes = [...imageNotes];
      updatedImageNotes.push("");

      setImages(updatedImages);
      setImageNotes(updatedImageNotes);
      setSelectedImageIndex(updatedImages.length - 1);
    };

    fileReader.readAsDataURL(uploadedFile);
  };

  const handleImageDeleteButtonClick = (imageToDeleteIndex) => {
    const updatedImages = [];
    const updatedImageNotes = [];

    for (let i = 0; i < images.length; i++) {
      if (i === imageToDeleteIndex) {
        continue;
      }

      updatedImages.push(images[i]);
      updatedImageNotes.push(imageNotes[i]);
    }

    setImages(updatedImages);
    setImageNotes(updatedImageNotes);

    if (updatedImages.length !== 0) {
      setSelectedImageNotes(updatedImageNotes[selectedImageIndex]);

      if (selectedImageIndex === updatedImages.length) {
        setSelectedImageIndex(updatedImages.length - 1);
      }
    } else {
      setSelectedImageNotes("");
      setSelectedImageIndex(-1);
    }
  };

  const handleCreateLogButtonClick = async (event) => {
    setIsUploading(true);

    const updatedImageNotes = [...imageNotes];
    if (selectedImageIndex !== -1) {
      updatedImageNotes[selectedImageIndex] = selectedImageNotes;
    }

    if (!validateAllFields(updatedImageNotes)) {
      setDisplayValidationErrors(true);
      setIsUploading(false);
      console.log("Validation errors");
      return;
    } else {
      setDisplayValidationErrors(false);
    }

    const newPatientLog = {
      patientId: selectedPatient._id,
      pointId: selectedPoint._id,
      logDatetime: new Date().toISOString(),
      notes: notes,
      prescription: prescription,
    };

    const response = await apiClient.patientLog.addNew(newPatientLog);
    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsUploading(false);
      return;
    }

    const newPatientLogId = response.result.patientLog._id;

    const failedToAddImageIndices = [];

    for (let i = 0; i < images.length; i++) {
      const formData = new FormData();
      formData.append("image", images[i].filePath);
      formData.append("imageNotes", updatedImageNotes[i]);

      const addImageResponse = await apiClient.patientLog.addImage(
        newPatientLogId,
        formData
      );
      if (!addImageResponse.success) {
        failedToAddImageIndices.push(i);
      }
    }

    if (failedToAddImageIndices.length > 0) {
      toast("Failed to upload some images.");
    }

    toast("New patient log created.");
    if (patientId === undefined) {
      setSelectedPatient(null);
    }
    // setSelectedPoint(null);
    setNotes("");
    setPrescription("");
    setSelectedImageIndex(-1);
    setImages([]);
    setImageNotes([]);

    setIsUploading(false);
  };

  useEffect(() => {
    document.title = "Create Patient Log";
  }, []);

  useEffect(() => {
    const updatedImageNotes = [...imageNotes];
    if (selectedImageIndex !== -1) {
      updatedImageNotes[selectedImageIndex] = selectedImageNotes;
    }
    validateAllFields(updatedImageNotes, false);
  }, [selectedPatient, selectedPoint, notes, selectedImageNotes]);

  useEffect(() => {
    if (
      previousSelectedImageIndex != -1 &&
      previousSelectedImageIndex < imageNotes.length &&
      imageNotes[previousSelectedImageIndex] !== selectedImageNotes
    ) {
      const updatedImageNotes = [...imageNotes];
      updatedImageNotes[previousSelectedImageIndex] = selectedImageNotes;
      setImageNotes(updatedImageNotes);
    }

    setPreviousSelectedImageIndex(selectedImageIndex);
    setSelectedImageNotes(imageNotes[selectedImageIndex]);
  }, [selectedImageIndex]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.createPatientLogPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        {patientId !== undefined ? (
          <MuiPatientsTopNavigation patientId={patientId} />
        ) : null}

        <PatientSelect
          defaultSelectedPatientId={patientId}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          disabled={isUploading}
          errorText={displayValidationErrors && validationErrors.patient}
        />

        <PointSelectNoChange
          defaultSelectedPointId={""}
          selectedPoint={selectedPoint}
          setSelectedPoint={setSelectedPoint}
          disabled={isUploading}
          errorText={displayValidationErrors && validationErrors.point}
        />

        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            disabled={isUploading}
            error={Boolean(displayValidationErrors && validationErrors.notes)}
            helperText={displayValidationErrors && validationErrors.notes}
          />
        </Box>

        <Box mb={3}>
          <TextField
            className={classes.inputTextField}
            label="Prescription"
            multiline
            rows={3}
            value={prescription}
            onChange={(e) => setPrescription(e.target.value)}
            disabled={isUploading}
          />
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
          >
            <Box
              width="50%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {selectedImageIndex !== -1 ? (
                <img
                  className={classes.largeImageDisplay}
                  src={images[selectedImageIndex].url}
                />
              ) : null}
            </Box>

            {selectedImageIndex !== -1 ? (
              <Box width="50%">
                <TextField
                  className={classes.inputTextField}
                  label="Image Notes"
                  multiline
                  rows={3}
                  value={selectedImageNotes}
                  onChange={(e) => setSelectedImageNotes(e.target.value)}
                  disabled={isUploading}
                  error={Boolean(
                    displayValidationErrors &&
                      validationErrors?.imageNotes?.length >
                        selectedImageIndex &&
                      validationErrors?.imageNotes[selectedImageIndex]
                  )}
                  helperText={
                    displayValidationErrors &&
                    validationErrors?.imageNotes?.length > selectedImageIndex &&
                    validationErrors?.imageNotes[selectedImageIndex]
                  }
                />

                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    color="error"
                    onClick={() =>
                      handleImageDeleteButtonClick(selectedImageIndex)
                    }
                    disabled={isUploading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            maxWidth="80%"
            overflow="auto"
          >
            {images.map((image, index) => (
              <Box mr={1} key={index}>
                <Button
                  variant={
                    index === selectedImageIndex ? "contained" : "outlined"
                  }
                  onClick={() => setSelectedImageIndex(index)}
                  disabled={isUploading}
                >
                  <img className={classes.smallImageDisplay} src={image.url} />
                </Button>
              </Box>
            ))}

            <Box mr={1}>
              <IconButton
                color={selectedImageIndex === -1 ? "primary" : "default"}
                size={selectedImageIndex === -1 ? "large" : "medium"}
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box width="100%" display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateLogButtonClick}
          >
            {isUploading ? (
              <BeatLoader color="#0000FF" loading={true} size={10} />
            ) : (
              "Create Log"
            )}
          </Button>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  createPatientLogPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: "100%",
  },
  largeImageDisplay: {
    display: "block",
    maxWidth: "90%",
    marginRight: 25,
    maxHeight: 300,
    width: "auto",
    height: "auto",
  },
  smallImageDisplay: {
    width: 50,
    height: 50,
    objectFit: "cover",
  },
}));
