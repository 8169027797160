import { useState, useEffect, useContext, useRef } from "react";
import {
  useParams,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";

import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import { addLabelToPatient, parsePatientLogObjectForJs } from "../api/apiUtils";
import SinglePatientDisplayer from "../components/SinglePatientDisplayer";
import SinglePointDisplayer from "../components/SinglePointDisplayer";
import {
  patientLogDeleteAllowedTimeInHours,
  patientLogEditAllowedTimeInHours,
} from "../config/appConfig";
import errorCodes from "../api/errorCodes";

export default function PatientLogDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const { user } = useContext(userContext);
  const { patientLogId } = useParams();
  const fileInputRef = useRef(null);

  const [originalPatientLog, setOriginalPatientLog] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [logDatetime, setLogDatetime] = useState(null);
  const [notes, setNotes] = useState("");
  const [prescription, setPrescription] = useState("");
  const [previousSelectedImageIndex, setPreviousSelectedImageIndex] =
    useState(-1);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [imageNotes, setImageNotes] = useState([]);
  const [selectedImageNotes, setSelectedImageNotes] = useState("");
  const [images, setImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [notFoundError, setNotFoundError] = useState("");
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [canBeEdited, setCanBeEdited] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [imagesToBeDeletedIds, setImagesToBeDeletedIds] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [getDetailsErrorTitle, setGetDetailsErrorTitle] = useState("");
  const [getDetailsErrorSubtitle, setGetDetailsErrorSubtitle] = useState("");

  const validateAllFields = (updatedImageNotes = null) => {
    const checkImageNotes =
      updatedImageNotes === null
        ? imageNotes.map((noteObj) => noteObj.notes)
        : updatedImageNotes.map((noteObj) => noteObj.notes);

    const newValidationErrors = validators.patientLogs.addNew(
      selectedPatient,
      selectedPoint,
      notes,
      checkImageNotes
    );
    setValidationErrors(newValidationErrors);

    updatedImageNotes.forEach((notes) => console.log(notes));

    if (newValidationErrors.imageNotes !== undefined) {
      let errorImageIndex = -1;
      for (let i = 0; i < newValidationErrors.imageNotes.length; i++) {
        console.log(`${i}: ${newValidationErrors.imageNotes[i]}`);
        if (newValidationErrors.imageNotes[i] !== "") {
          if (errorImageIndex === -1) {
            errorImageIndex = i;
          }
          // break;
        }
      }

      console.log(errorImageIndex);

      setSelectedImageIndex(errorImageIndex);
    }

    return Object.keys(newValidationErrors).length === 0;
  };

  const getPatientLogDetailsFromApi = async (updateLoadingStatus = true) => {
    if (updateLoadingStatus) {
      setIsLoading(true);
    }

    const response = await apiClient.patientLog.getDetail(patientLogId);
    if (!response.success) {
      console.log(response);
      if (response.error.validationErrors) {
        setGetDetailsErrorTitle("Invalid Patient Log URL.");
        setGetDetailsErrorSubtitle(
          "It looks like you have entered an invalid URL for a Patient Log."
        );
      } else {
        setGetDetailsErrorTitle("Failed to fetch Patient Log");
        setGetDetailsErrorSubtitle("Refresh the page to try again.");
      }

      setIsLoading(false);
      return;
    }

    response.result.patientLog = parsePatientLogObjectForJs(
      response.result.patientLog
    );

    setOriginalPatientLog(response.result.patientLog);

    setSelectedPatient(addLabelToPatient(response.result.patientLog.patient));
    setSelectedPoint(response.result.patientLog.point);
    setLogDatetime(response.result.patientLog.logDatetime);
    setNotes(response.result.patientLog.notes);
    setPrescription(response.result.patientLog.prescription);
    setImages(response.result.patientLog.images);
    setImageNotes(
      response.result.patientLog.images.map((image) => ({
        notes: image.notes,
        _id: image._id,
        hasChanged: false,
      }))
    );

    setCanBeDeleted(
      Math.abs(new Date() - response.result.patientLog?.createdAt) / 36e5 <
        patientLogDeleteAllowedTimeInHours
    );
    setCanBeEdited(
      Math.abs(new Date() - response.result.patientLog?.createdAt) / 36e5 <
        patientLogEditAllowedTimeInHours
    );

    setIsLoading(false);
  };

  const handleEditButtonClick = async () => {
    setIsInEditMode(true);
  };

  const handleDeleteButtonClick = async () => {
    setIsUploading(true);

    const response = await apiClient.patientLog.delete(patientLogId);
    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsUploading(false);
      return;
    }

    toast("Log deleted successfully.");
    setIsUploading(false);

    setTimeout(() => {
      if (doesAnyHistoryEntryExist) {
        navigate(-1);
      } else {
        navigate("/");
      }
    }, 2000);
  };

  const handleSaveButtonClick = async () => {
    // update details
    // change the image notes that have to be changed
    // add new images: the images in the images array that do not have an id
    // delete the images to be deleted

    setIsUploading(true);

    const updatedImageNotes = [...imageNotes];
    if (selectedImageIndex !== -1) {
      if (updatedImageNotes[selectedImageIndex].notes !== selectedImageNotes) {
        updatedImageNotes[selectedImageIndex].notes = selectedImageNotes;
        updatedImageNotes[selectedImageIndex].hasChanged = true;
      }
    }

    if (!validateAllFields(updatedImageNotes)) {
      setDisplayValidationErrors(true);
      setIsUploading(false);
      return;
    } else {
      setDisplayValidationErrors(false);
    }

    const updatedPatientLog = {
      pointId: selectedPoint._id,
      logDatetime: originalPatientLog.logDatetime.toISOString(),
      notes: notes,
      prescription: prescription,
    };

    const response = await apiClient.patientLog.update(
      patientLogId,
      updatedPatientLog
    );
    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsUploading(false);
      return;
    }
    console.log("Updated details.");

    // Update the changed image notes
    const failedToUpdateImageNotesIndices = [];
    for (let i = 0; i < updatedImageNotes.length; i++) {
      if (
        updatedImageNotes[i]._id !== undefined &&
        updatedImageNotes[i].hasChanged
      ) {
        const imageNotesUpdateResponse =
          await apiClient.patientLog.updateImageNotes(
            patientLogId,
            updatedImageNotes[i]._id,
            updatedImageNotes[i].notes
          );
        if (!imageNotesUpdateResponse.success) {
          failedToUpdateImageNotesIndices.push(i);
        }
        console.log("Updated notes for an image.");
      }
    }
    if (failedToUpdateImageNotesIndices.length > 0) {
      toast("Failed to update notes for some images.");
    }

    // Adding the new images
    const failedToAddImageIndices = [];
    for (let i = 0; i < images.length; i++) {
      if (images[i]._id === undefined) {
        const formData = new FormData();
        formData.append("image", images[i].filePath);
        formData.append("imageNotes", updatedImageNotes[i].notes);

        const addImageResponse = await apiClient.patientLog.addImage(
          patientLogId,
          formData
        );
        if (!addImageResponse.success) {
          failedToAddImageIndices.push(i);
        }
        console.log("Added an image.");
      }
    }
    if (failedToAddImageIndices.length > 0) {
      toast("Failed to upload some images.");
    }

    // Deleting the images to be deleted
    const failedToDeleteImageIds = [];
    for (let i = 0; i < imagesToBeDeletedIds.length; i++) {
      const deleteImageResponse = await apiClient.patientLog.deleteImage(
        patientLogId,
        imagesToBeDeletedIds[i]
      );
      if (!deleteImageResponse.success) {
        failedToDeleteImageIds.push(imagesToBeDeletedIds[i]);
      }
      console.log("Deleted an image.");
    }
    if (failedToDeleteImageIds.length > 0) {
      toast("Failed to delete some images.");
    }

    await getPatientLogDetailsFromApi(false);
    setIsInEditMode(false);
    setHasUnsavedChanges(false);
    setIsUploading(false);
  };

  const handleDiscardButtonClick = async () => {
    if (!hasUnsavedChanges) {
      setIsInEditMode(false);
      return;
    }

    setSelectedPatient(addLabelToPatient(originalPatientLog.patient));
    setSelectedPoint(originalPatientLog.point);
    setNotes(originalPatientLog.notes);
    setPrescription(originalPatientLog.prescription);
    setImages(originalPatientLog.images);
    setImageNotes(
      originalPatientLog.images.map((image) => ({
        id: image._id,
        notes: image.notes,
        hasChanged: false,
      }))
    );

    setIsInEditMode(false);
    setHasUnsavedChanges(false);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "image/jpeg") {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const file = e.target.result;

        const updatedImages = [...images];
        updatedImages.push({
          filePath: uploadedFile,
          url: file,
        });

        const updatedImageNotes = [...imageNotes];
        updatedImageNotes.push({
          notes: "",
          hasChanged: true,
        });

        setImages(updatedImages);
        setImageNotes(updatedImageNotes);
        setSelectedImageIndex(updatedImages.length - 1);
        setHasUnsavedChanges(true);
      };

      fileReader.readAsDataURL(uploadedFile);
    }
  };

  const handleImageDeleteButtonClick = (imageToDeleteIndex) => {
    const updatedImages = [];
    const updatedImageNotes = [];

    if (images[imageToDeleteIndex]._id !== undefined) {
      const updatedImagesToBeDeletedIds = [...imagesToBeDeletedIds];
      updatedImagesToBeDeletedIds.push(images[imageToDeleteIndex]._id);
      setImagesToBeDeletedIds(updatedImagesToBeDeletedIds);
    }

    for (let i = 0; i < images.length; i++) {
      if (i === imageToDeleteIndex) {
        continue;
      }

      updatedImages.push(images[i]);
      updatedImageNotes.push(imageNotes[i]);
    }

    setImages(updatedImages);
    setImageNotes(updatedImageNotes);

    if (updatedImages.length !== 0) {
      setSelectedImageNotes(updatedImageNotes[selectedImageIndex].notes);

      if (selectedImageIndex === updatedImages.length) {
        setSelectedImageIndex(updatedImages.length - 1);
      }
    } else {
      setSelectedImageNotes("");
      setSelectedImageIndex(-1);
    }
  };

  useEffect(() => {
    document.title = "Patient Log";

    getPatientLogDetailsFromApi();
  }, []);

  useEffect(() => {
    if (selectedImageIndex === -1) {
      return;
    }

    if (
      previousSelectedImageIndex != -1 &&
      previousSelectedImageIndex < imageNotes.length &&
      imageNotes[previousSelectedImageIndex] !== selectedImageNotes
    ) {
      const updatedImageNotes = [...imageNotes];
      if (
        updatedImageNotes[previousSelectedImageIndex].notes !==
        selectedImageNotes
      ) {
        updatedImageNotes[previousSelectedImageIndex].notes =
          selectedImageNotes;
        updatedImageNotes[previousSelectedImageIndex].hasChanged = true;
        setImageNotes(updatedImageNotes);
      }
    }

    setPreviousSelectedImageIndex(selectedImageIndex);
    setSelectedImageNotes(imageNotes[selectedImageIndex].notes);
  }, [selectedImageIndex]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoading) {
    return (
      <div className={classes.patientLogDetailsPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Box mb={8} />
          <Skeleton
            width={50}
            height={50}
            circle={true}
            className={classes.skeleton}
          />
          <Skeleton height={35} />
          <Skeleton height={35} />
          <Skeleton height={100} count={2} />
        </Container>
      </div>
    );
  }

  if (getDetailsErrorTitle !== "") {
    return (
      <div className={classes.createPatientLogPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Typography color="error" variant="h3">
            {getDetailsErrorTitle}
          </Typography>
          <Typography color="error" variant="p">
            {getDetailsErrorSubtitle}
          </Typography>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.patientLogDetailsPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />

        {isInEditMode ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            {isUploading ? (
              <Box mr={2}>
                <ClipLoader color={"#00FF00"} loading={true} size={25} />
              </Box>
            ) : null}
            <Box mr={2}>
              <Tooltip
                title={!hasUnsavedChanges ? "No changes have been made." : ""}
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveButtonClick}
                    disabled={isUploading || !hasUnsavedChanges}
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            </Box>

            <Box mr={2}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDiscardButtonClick}
                disabled={isUploading}
              >
                {hasUnsavedChanges ? "Discard" : "Stop Editing"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            <Box mr={2}>
              <Tooltip
                title={
                  canBeEdited
                    ? ""
                    : `A log can only be edited up till ${patientLogEditAllowedTimeInHours} hours after creation.`
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditButtonClick}
                    disabled={!canBeEdited}
                  >
                    Edit
                  </Button>
                </span>
              </Tooltip>
            </Box>

            <Box mr={2}>
              <Tooltip
                title={
                  canBeDeleted
                    ? ""
                    : `A log can only be deleted up till ${patientLogDeleteAllowedTimeInHours} hours after creation.`
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteButtonClick}
                    disabled={!canBeDeleted}
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        )}

        <SinglePatientDisplayer patient={selectedPatient} />

        <SinglePointDisplayer point={selectedPoint} />

        <Box mb={2}>
          <Typography>{logDatetime?.toString().slice(0, 24)}</Typography>
        </Box>

        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
              setHasUnsavedChanges(true);
            }}
            disabled={isUploading || !isInEditMode}
            error={Boolean(displayValidationErrors && validationErrors.notes)}
            helperText={displayValidationErrors && validationErrors.notes}
          />
        </Box>

        <Box mb={3}>
          <TextField
            className={classes.inputTextField}
            label="Prescription"
            multiline
            rows={3}
            value={prescription}
            onChange={(e) => {
              setPrescription(e.target.value);
              setHasUnsavedChanges(true);
            }}
            disabled={isUploading || !isInEditMode}
          />
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
          >
            <Box
              width="50%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {selectedImageIndex !== -1 ? (
                <img
                  className={classes.largeImageDisplay}
                  src={images[selectedImageIndex].url}
                />
              ) : null}
            </Box>

            {selectedImageIndex !== -1 ? (
              <Box width="50%">
                <TextField
                  className={classes.inputTextField}
                  label="Image Notes"
                  multiline
                  rows={3}
                  value={selectedImageNotes}
                  onChange={(e) => {
                    setSelectedImageNotes(e.target.value);
                    setHasUnsavedChanges(true);
                  }}
                  disabled={isUploading || !isInEditMode}
                  error={Boolean(
                    displayValidationErrors &&
                      validationErrors?.imageNotes[selectedImageIndex]
                  )}
                  helperText={
                    displayValidationErrors &&
                    validationErrors?.imageNotes[selectedImageIndex]
                  }
                />

                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    color="error"
                    onClick={() => {
                      handleImageDeleteButtonClick(selectedImageIndex);
                      setHasUnsavedChanges(true);
                    }}
                    disabled={isUploading || !isInEditMode}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {images.map((image, index) => (
              <Box mr={1} key={index}>
                <Button
                  variant={
                    index === selectedImageIndex ? "contained" : "outlined"
                  }
                  onClick={() => setSelectedImageIndex(index)}
                  disabled={isUploading}
                >
                  <img className={classes.smallImageDisplay} src={image.url} />
                </Button>
              </Box>
            ))}

            <Box mr={1}>
              <IconButton
                color={selectedImageIndex === -1 ? "primary" : "default"}
                size={selectedImageIndex === -1 ? "large" : "medium"}
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading || !isInEditMode}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {originalPatientLog?.updatedBy?.name ? (
          <Box mt={2}>
            <Typography fontSize={12} variant="p">
              {"Patient Log last updated by "}
            </Typography>

            <Typography fontSize={12} variant="p" color="primary">
              {`${originalPatientLog.updatedBy.name} `}
            </Typography>

            <Typography fontSize={12} variant="p">
              {"at "}
            </Typography>

            <Typography fontSize={12} variant="p" color="primary">
              {new Date(originalPatientLog?.updatedAt).toString().slice(0, 24)}
            </Typography>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  patientLogDetailsPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  skeleton: {
    marginBottom: 20,
  },
  inputTextField: {
    width: "100%",
  },
  largeImageDisplay: {
    display: "block",
    maxWidth: "90%",
    marginRight: 25,
    maxHeight: 300,
    width: "auto",
    height: "auto",
  },
  smallImageDisplay: {
    width: 50,
    height: 50,
  },
}));
