import { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import {
  TextField,
  Box,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search as SearchIcon } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";

import apiClient from "../api/apiServices";
import { parsePatientObjectForFrontend } from "../api/apiUtils";
import SortingKeySelect from "../components/SortingKeySelect";
import PatientListHeader from "../components/PatientListHeader";
import PatientListItem from "../components/PatientListItem";
import userContext from "../context/userContext";
import stateContext from "../context/stateContext";

export default function SearchPatients() {
  const classes = useStyles();
  const { user } = useContext(userContext);
  const {
    searchPatientsPageErrorText,
    setSearchPatientsPageErrorText,
    searchPatientsPageSearchQuery,
    setSearchPatientsPageSearchQuery,
    searchPatientsPageSearchResults,
    setSearchPatientsPageSearchResults,
    searchPatientsPageSortingKey,
    setSearchPatientsPageSortingKey,
  } = useContext(stateContext);

  const [isLoading, setIsLoading] = useState(false);

  const sortPatients = (inputPatients, inputSortingKey) => {
    switch (inputSortingKey) {
      case "Name": {
        inputPatients.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Name Desc": {
        inputPatients.sort((a, b) => {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "Last Seen": {
        inputPatients.sort((a, b) => {
          const d1 = new Date(a.lastSeenAt?.datetime);
          const d2 = new Date(b.lastSeenAt?.datetime);
          return d1 - d2;
        });
        break;
      }
      case "Last Seen Desc": {
        inputPatients.sort((a, b) => {
          const d1 = new Date(a.lastSeenAt?.datetime);
          const d2 = new Date(b.lastSeenAt?.datetime);
          return d2 - d1;
        });
        break;
      }
      case "Last Seen Point": {
        inputPatients.sort((a, b) => {
          if (a.lastSeenAt?.point.name > b.lastSeenAt?.point.name) {
            return 1;
          }
          if (a.lastSeenAt?.point.name < b.lastSeenAt?.point.name) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Last Seen Point Desc": {
        inputPatients.sort((a, b) => {
          if (a.lastSeenAt?.point.name > b.lastSeenAt?.point.name) {
            return -1;
          }
          if (a.lastSeenAt?.point.name < b.lastSeenAt?.point.name) {
            return 1;
          }
          return 0;
        });
        break;
      }
      default: {
      }
    }

    return inputPatients;
  };

  const handleSearchButtonClick = async (event) => {
    setIsLoading(true);

    const response = await apiClient.patient.search(
      searchPatientsPageSearchQuery
    );

    if (!response.success) {
      console.log(response);
      setSearchPatientsPageErrorText(response.error.message);
      setIsLoading(false);
      return;
    }

    let responseSearchResults = response.result.patients.map((searchResult) =>
      parsePatientObjectForFrontend(searchResult)
    );
    responseSearchResults = sortPatients(
      responseSearchResults,
      searchPatientsPageSortingKey
    );
    setSearchPatientsPageSearchResults(responseSearchResults);

    if (responseSearchResults.length === 0) {
      setSearchPatientsPageErrorText(
        "No patients found for the given search query."
      );
    } else {
      setSearchPatientsPageErrorText("");
    }

    setIsLoading(false);
  };

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.searchPatientsPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <TextField
            className={classes.inputTextFieldFlexGrowMarginRight}
            label="Search Patients..."
            type="text"
            value={searchPatientsPageSearchQuery}
            onChange={(e) => setSearchPatientsPageSearchQuery(e.target.value)}
          />

          <Box
            width="10%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleSearchButtonClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <ClipLoader loading={isLoading} color="blue" size={25} />
              ) : (
                <SearchIcon />
              )}
            </IconButton>
          </Box>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {searchPatientsPageErrorText !== "" ? (
            <Box mb={2}>
              <Typography variant="h6" color="error">
                {searchPatientsPageErrorText}
              </Typography>
            </Box>
          ) : searchPatientsPageSearchResults.length ===
            0 ? null : window.innerWidth >= 700 ? (
            <PatientListHeader
              sortingKey={searchPatientsPageSortingKey}
              setSortingKey={(newSortingKey) => {
                setSearchPatientsPageSortingKey(newSortingKey);
                let updatedPatients = [...searchPatientsPageSearchResults];
                updatedPatients = sortPatients(updatedPatients, newSortingKey);
                setSearchPatientsPageSearchResults(updatedPatients);
              }}
            />
          ) : (
            <SortingKeySelect
              sortingKey={searchPatientsPageSortingKey}
              setSortingKey={(newSortingKey) => {
                setSearchPatientsPageSortingKey(newSortingKey);
                let updatedPatients = [...searchPatientsPageSearchResults];
                updatedPatients = sortPatients(updatedPatients, newSortingKey);
                setSearchPatientsPageSearchResults(updatedPatients);
              }}
              allSortingKeys={[
                "Name",
                "Last Seen Point",
                "Last Seen",
                "Name Desc",
                "Last Seen Point Desc",
                "Last Seen Desc",
              ]}
            />
          )}

          {searchPatientsPageSearchResults.map((searchResult, index) => (
            <PatientListItem
              patientDetails={searchResult}
              index={index}
              key={index}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  searchPatientsPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextFieldFlexGrowMarginRight: {
    flexGrow: 1,
    "&&": {
      marginRight: 20,
    },
  },
}));
