import { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Container,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";

import userContext from "../context/userContext";
import PointSelect from "../components/PointSelect";
import PatientLogListItemWithAvatarAndName from "../components/PatientLogListItemWithAvatarAndName";
import PatientLogListHeader from "../components/PatientLogListHeader";
import SortingKeySelect from "../components/SortingKeySelect";
import apiClient from "../api/apiServices";
import { parsePatientLogObjectForJs } from "../api/apiUtils";
import localStorageKeys from "../localStorage/keys";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../localStorage/localStorage";
import stateContext from "../context/stateContext";

export default function Home() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, currentPoint } = useContext(userContext);
  const {
    homePageErrorText,
    setHomePageErrorText,
    homePageAreAllLogsLoaded,
    setHomePageAreAllLogsLoaded,
    homePagePatientLogs,
    setHomePagePatientLogs,
    homePageSortingKey,
    setHomePageSortingKey,
  } = useContext(stateContext);

  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  // const [homePageErrorText, setHomePageErrorText] = useState("");
  // const [homePageAreAllLogsLoaded, setHomePageAreAllLogsLoaded] = useState(false);
  // const [homePagePatientLogs, setHomePagePatientLogs] = useState([]);
  // const [homePageSortingKey, setHomePageSortingKey] = useState("Date & Time Desc");

  // const loadLastState = () => {
  //   console.log("All Logs of Patient: Loading last state from local storage.");
  //   const lastState = loadFromLocalStorage(localStorageKeys.lastState.homePage);
  //   if (lastState === null || lastState.pointId !== currentPoint?._id) {
  //     console.log("Refetch required");
  //     setIsLoadingFirstTime(false);
  //     getPointLogsForLimitFromApi(30);
  //     return;
  //   }

  //   setHomePageErrorText(lastState.homePageErrorText);
  //   setHomePagePatientLogs(
  //     sortPatientLogs(
  //       lastState.homePagePatientLogs,
  //       lastState.homePageSortingKey
  //     )
  //   );
  //   setHomePageSortingKey(lastState.homePageSortingKey);

  //   if (currentPoint === undefined || currentPoint === null) {
  //     setIsLoadingFirstTime(false);
  //   } else {
  //     setTimeout(() => {
  //       setIsLoadingFirstTime(false);
  //     }, 2500);
  //   }
  // };

  // const saveCurrentState = () => {
  //   console.log("All Logs of Patient: Saving current state to local storage.");
  //   const currentState = {
  //     pointId: currentPoint?._id,
  //     homePageErrorText: homePageErrorText,
  //     homePagePatientLogs: homePagePatientLogs,
  //     homePageSortingKey: homePageSortingKey,
  //   };

  //   saveToLocalStorage(localStorageKeys.lastState.homePage, currentState);
  // };

  const sortPatientLogs = (inputPatientLogs, inputSortingKey) => {
    switch (inputSortingKey) {
      case "Name": {
        inputPatientLogs.sort((a, b) => {
          if (a.patient.name > b.patient.name) {
            return 1;
          }
          if (a.patient.name < b.patient.name) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Name Desc": {
        inputPatientLogs.sort((a, b) => {
          if (a.patient.name > b.patient.name) {
            return -1;
          }
          if (a.patient.name < b.patient.name) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "Date & Time": {
        inputPatientLogs.sort((a, b) => {
          const d1 = new Date(a.logDatetime);
          const d2 = new Date(b.logDatetime);
          return d1 - d2;
        });
        break;
      }
      case "Date & Time Desc": {
        inputPatientLogs.sort((a, b) => {
          const d1 = new Date(a.logDatetime);
          const d2 = new Date(b.logDatetime);
          return d2 - d1;
        });
        break;
      }
      case "Notes": {
        inputPatientLogs.sort((a, b) => {
          if (a.notes > b.notes) {
            return 1;
          }
          if (a.notes < b.notes) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Notes Desc": {
        inputPatientLogs.sort((a, b) => {
          if (a.notes > b.notes) {
            return -1;
          }
          if (a.notes < b.notes) {
            return 1;
          }
          return 0;
        });
        break;
      }
      default: {
      }
    }

    return inputPatientLogs;
  };

  const getPointLogsForLimitFromApi = async (limit, reset) => {
    if (currentPoint === null) {
      console.log("No current point");
      return;
    }

    setIsLoadingLogs(true);

    const searchParams = {
      pointId: currentPoint._id,
      limit: limit,
    };

    if (!reset && homePagePatientLogs.length !== 0) {
      searchParams.endTimestamp =
        homePagePatientLogs[
          homePagePatientLogs.length - 1
        ].logDatetime.getTime() - 1;
    }

    const response = await apiClient.patientLog.search(searchParams);

    if (!response.success) {
      setHomePageErrorText(response.error.message);
      console.log(response);
      setIsLoadingLogs(false);
      setIsLoadingFirstTime(false);
      return;
    }

    if (response.result.patientLogs.length === 0) {
      if (reset) {
        setHomePageErrorText("No logs to show.");
        setHomePagePatientLogs([]);
      }

      setHomePageAreAllLogsLoaded(true);
      setIsLoadingLogs(false);
      setIsLoadingFirstTime(false);
      return;
    }

    let newPatientLogs = response.result.patientLogs.map((patientLog) =>
      parsePatientLogObjectForJs(patientLog)
    );

    newPatientLogs = sortPatientLogs(newPatientLogs, homePageSortingKey);

    if (!reset) {
      let updatedPatientLogs = [...homePagePatientLogs];
      updatedPatientLogs = updatedPatientLogs.concat(newPatientLogs);
      setHomePagePatientLogs(updatedPatientLogs);
    } else {
      setHomePagePatientLogs(newPatientLogs);
      setHomePageAreAllLogsLoaded(false);
    }

    setIsLoadingLogs(false);
    setHomePageErrorText("");
    setIsLoadingFirstTime(false);
  };

  const handleAddLogButtonClick = (event) => {
    event.preventDefault();
    navigate("/patient-logs/add");
  };

  const handleAddPatientButtonClick = (event) => {
    event.preventDefault();
    navigate("/patients/add");
  };

  const handleLoadMoreButtonClick = (event) => {
    getPointLogsForLimitFromApi(30, false);
  };

  useEffect(() => {
    document.title = `PMA - Home`;
    getPointLogsForLimitFromApi(30);
    console.log("Home component rerendered");
    // loadLastState();
  }, []);

  useEffect(() => {
    if (isLoadingFirstTime) {
      return;
    }

    setHomePageAreAllLogsLoaded(false);
    getPointLogsForLimitFromApi(30, true);
  }, [currentPoint]);

  useEffect(() => {
    // if loading for the first time: avoid
    if (isLoadingFirstTime) {
      return;
    }

    let updatedPatientLogs = [...homePagePatientLogs];
    updatedPatientLogs = sortPatientLogs(
      updatedPatientLogs,
      homePageSortingKey
    );
    setHomePagePatientLogs(updatedPatientLogs);
  }, [homePageSortingKey]);

  // useEffect(() => {
  //   // if loading for the first time: avoid
  //   if (isLoadingFirstTime) {
  //     return;
  //   }

  //   // if current state is initial state: avoid
  //   if (
  //     homePageErrorText === "" &&
  //     homePagePatientLogs.length === 0 &&
  //     homePageSortingKey === "Date & Time Desc"
  //   ) {
  //     return;
  //   }

  //   saveCurrentState();
  // }, [homePageErrorText, homePagePatientLogs, homePageSortingKey]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.homePageContainer}>
      <Container
        maxWidth="md"
        className={classes.container}
        // sx={{ backgroundColor: "red" }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <PointSelect allowUpdatePoint={true} />
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
          mb={3}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddLogButtonClick}
          >
            Add Log
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPatientButtonClick}
          >
            Add Patient
          </Button>

          <IconButton
            color="warning"
            onClick={() => {
              setIsLoadingFirstTime(true);
              getPointLogsForLimitFromApi(30, true);
            }}
            disabled={isLoadingFirstTime}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {homePageErrorText !== "" ? (
            <Box mb={2}>
              <Typography variant="h6" color="error">
                {homePageErrorText}
              </Typography>
            </Box>
          ) : homePagePatientLogs.length === 0 ? null : window.innerWidth >=
            700 ? (
            <PatientLogListHeader
              sortingKey={homePageSortingKey}
              setSortingKey={setHomePageSortingKey}
            />
          ) : (
            <SortingKeySelect
              sortingKey={homePageSortingKey}
              setSortingKey={setHomePageSortingKey}
              allSortingKeys={[
                "Name",
                "Date & Time",
                "Notes",
                "Name Desc",
                "Date & Time Desc",
                "Notes Desc",
              ]}
            />
          )}

          {homePagePatientLogs.map((patientLog, index) => (
            <PatientLogListItemWithAvatarAndName
              patientLogDetails={patientLog}
              index={index}
              key={index}
            />
          ))}
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Tooltip
            title={homePageAreAllLogsLoaded ? "All logs have been loaded." : ""}
          >
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled={isLoadingLogs || homePageAreAllLogsLoaded}
                onClick={handleLoadMoreButtonClick}
              >
                {isLoadingLogs ? (
                  <BeatLoader color="#42a5f5" loading={true} size={10} />
                ) : (
                  "Load More"
                )}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  homePageContainer: {
    width: "100%",
    // backgroundColor: "yellow",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
}));
