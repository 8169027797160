import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ConfirmDialog({
  open,
  onClose,
  title,
  contentText,
  onAgree,
  onDisagree,
  color,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color={color}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color={color}>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onDisagree}
          autoFocus
        >
          Cancel
        </Button>
        <Button color={color} variant="outlined" onClick={onAgree}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
