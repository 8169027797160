import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
  Home as HomeIcon,
  PersonSearch as PersonSearchIcon,
  ManageSearch as ManageSearchIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { getPathnameWithoutParams } from "../utils/jsUtils";

const patientsTopNavigationMainLocations = [
  "/patients",
  "/patient-logs/for-patient",
  "/patient-documents/for-patient",
  "/patient-logs/add",
  "/patient-documents/add",
];

export default function MuiPatientsTopNavigation({ patientId }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(
    getPathnameWithoutParams(location.pathname)
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    
    setValue(getPathnameWithoutParams(location.pathname));
    // check if pathname is in the list to avoid errors
  }, [location]);

  return (
    <Box width="100%" maxWidth="md" mb={2}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          label="Details"
          value={patientsTopNavigationMainLocations[0]}
          onClick={() => navigate(`/patients/${patientId}`)}
        />
        <Tab
          label="Logs"
          value={patientsTopNavigationMainLocations[1]}
          onClick={() => navigate(`/patient-logs/for-patient/${patientId}`)}
        />
        <Tab
          label="Docs"
          value={patientsTopNavigationMainLocations[2]}
          onClick={() =>
            navigate(`/patient-documents/for-patient/${patientId}`)
          }
        />
        <Tab
          label="Add Log"
          value={patientsTopNavigationMainLocations[3]}
          onClick={() => navigate(`/patient-logs/add/${patientId}`)}
        />
        <Tab
          label="Add Doc"
          value={patientsTopNavigationMainLocations[4]}
          onClick={() => navigate(`/patient-documents/add/${patientId}`)}
        />
      </Tabs>
    </Box>
  );
}
