import { Box, IconButton } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

export default function CustomDatePicker({
  label,
  selected,
  onChange,
  disabled,
  handleClearButtonClick,
  errorText,
  mb,
}) {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mb={mb ? mb : 2}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
          <DateTimePicker
            label={label}
            value={dayjs(selected)}
            onChange={(newValue) => onChange(newValue.toDate())}
            disabled={disabled}
            slotProps={{
              textField: {
                error: !!errorText,
                helperText: errorText,
              },
            }}
            onFocus={() => console.log("Focused")}
            onBlur={() => console.log("Blurred")}
          />
        </DemoContainer>
      </LocalizationProvider>

      <IconButton color="error" onClick={handleClearButtonClick}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
}
