import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function PatientListItem({ patientDetails, index }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleListItemClick = (event) => {
    navigate(`/patients/${patientDetails._id}`);
  };

  return (
    <Box
      className={classes.listItemContainer}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor={index % 2 === 0 ? "#EEEEEE" : "#DEDEDE"}
      borderRadius={5}
      p={1}
      mb={2}
      onClick={handleListItemClick}
    >
      <Box mr={2}>
        <Avatar alt={patientDetails.name} src={patientDetails.avatarImageUrl} />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={5}>
          <Typography noWrap>{patientDetails.name}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography noWrap>
            {patientDetails?.lastSeenAt?.point?.name !== undefined
              ? patientDetails.lastSeenAt.point.name
              : "No Logs"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography noWrap>
            {patientDetails?.lastSeenAt?.datetime !== undefined
              ? new Date(patientDetails.lastSeenAt?.datetime)
                  .toString()
                  .slice(0, 24)
              : "This patient does not have any logs yet."}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#CCC",
    },
  },
}));
