import { useContext } from "react";
import { makeStyles } from "@mui//styles";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";

import userContext from "../context/userContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));

function DoubleAuthAttempt() {
  const classes = useStyles();

  const navigate = useNavigate();
  const { user } = useContext(userContext);

  const homeButtonOnClick = async (event) => {
    event.preventDefault();
    navigate("/");
  };

  const logoutButtonOnClick = async (event) => {
    event.preventDefault();
    navigate("/user-profile");
  };

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box m={2}>
        <Typography variant="h4" color="primary" gutterBottom>
          Oops. It looks like you're already logged in.
        </Typography>
      </Box>

      <Box
        m={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Box m={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={homeButtonOnClick}
          >
            Home
          </Button>
        </Box>

        <Box m={2}>
          <Button
            variant="contained"
            color="error"
            onClick={logoutButtonOnClick}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default DoubleAuthAttempt;
