import { useContext, useEffect } from "react";
import {
  Box,
  IconButton,
  Autocomplete,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";

import userContext from "../context/userContext";

export default function PatientSelect({
  defaultSelectedPatientId,
  selectedPatient,
  setSelectedPatient,
  errorText,
}) {
  const { patientsLocalCache, updatePatientsLocalCache } =
    useContext(userContext);

  useEffect(() => {
    if (selectedPatient !== null) {
      return;
    }

    if (defaultSelectedPatientId !== undefined) {
      const defaultSelectedPatient = patientsLocalCache?.patients.find(
        (patient) => patient._id === defaultSelectedPatientId
      );

      if (defaultSelectedPatient !== undefined) {
        setSelectedPatient(defaultSelectedPatient);
      }
    }
  }, [defaultSelectedPatientId]);

  return (
    <Box width="100%" maxWidth="sm" mb={2}>
      <Box
        maxWidth="sm"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box width="15%" mr={2}>
          <Avatar
            src={selectedPatient?.avatarImageUrl}
            alt={selectedPatient !== "" ? selectedPatient?.name : "-"}
            sx={{ width: 50, height: 50 }}
          />
        </Box>

        <Box
          width="70%"
          border={errorText ? 1 : 0}
          borderRadius={1}
          borderColor="red"
        >
          <Autocomplete
            disablePortal
            value={selectedPatient}
            options={patientsLocalCache?.patients}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: "100%" }}
            onChange={(event, value) => setSelectedPatient(value)}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option._id}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                value={selectedPatient?.label}
                {...params}
                label="Select Patient"
              />
            )}
          />
        </Box>

        <Box width="15%" ml={2}>
          <IconButton
            onClick={() => {
              setSelectedPatient(null);
              updatePatientsLocalCache();
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      <Box ml={2}>
        <Typography fontSize={12} variant="p" color="error">
          {errorText ? errorText : ""}
        </Typography>
      </Box>
    </Box>
  );
}
