import { useContext } from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, Navigate } from "react-router-dom";

import userContext from "../context/userContext";

const Register = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { user } = useContext(userContext);

  const backToLoginPageButtonOnClick = async (event) => {
    event.preventDefault();
    navigate("/auth/login");
  };

  if (user) {
    return <Navigate to="/auth/double-auth-attempt" />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box m={2}>
        <Typography variant="h4" color="error" gutterBottom>
          Registeration of new accounts is disabled. Please contact an admin for
          more details.
        </Typography>
      </Box>

      <Box m={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={backToLoginPageButtonOnClick}
        >
          Back to Login Page
        </Button>
      </Box>
    </Container>
  );
};

export default Register;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));
