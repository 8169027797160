const patientDeleteAllowedTimeInHours = 24;
const patientLogDeleteAllowedTimeInHours = 24;
const patientLogEditAllowedTimeInHours = 48;
const patientDocumentDeleteAllowedTimeInHours = 24;
const appName = "PMA";

export {
  appName,
  patientDeleteAllowedTimeInHours,
  patientLogDeleteAllowedTimeInHours,
  patientLogEditAllowedTimeInHours,
  patientDocumentDeleteAllowedTimeInHours,
};
