import { useContext, useEffect } from "react";
import {
  Avatar,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";

import userContext from "../context/userContext";

export default function PointSelectNoChange({
  defaultSelectedPointId,
  selectedPoint,
  setSelectedPoint,
  errorText,
}) {
  const { currentPoint, allPoints } = useContext(userContext);

  const pointsLocalCache = {
    points: allPoints,
  };

  useEffect(() => {
    console.log("default changed");
    if (defaultSelectedPointId !== undefined) {
      const defaultSelectedPoint = pointsLocalCache.points.find(
        (point) => point._id === defaultSelectedPointId
      );

      if (defaultSelectedPoint !== undefined) {
        setSelectedPoint(defaultSelectedPoint);
        return;
      }
    }

    if (selectedPoint !== null) {
      return;
    }

    if (currentPoint !== undefined || currentPoint !== null) {
      setSelectedPoint(currentPoint);
      return;
    }
  }, [
    defaultSelectedPointId,
    allPoints, //  pointsLocalCache
  ]);

  return (
    <Box width="100%" maxWidth="sm" mb={2}>
      <Box
        maxWidth="sm"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box width="15%" mr={2}>
          <Avatar
            src={
              selectedPoint?.avatarImageUrl
                ? selectedPoint?.avatarImageUrl
                : "This is an invalid src"
            }
            alt="?"
            sx={{ width: 50, height: 50 }}
          />
        </Box>
        <Box width="70%">
          <Autocomplete
            disablePortal
            value={selectedPoint || null}
            options={pointsLocalCache?.points}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            sx={{ width: "100%" }}
            onChange={(event, value) => setSelectedPoint(value)}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option._id}>
                  {option?.name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                value={selectedPoint?.name}
                {...params}
                label="Select Point"
              />
            )}
          />
        </Box>

        <Box width="15%" ml={2}></Box>
      </Box>
      <Box ml={2}>
        <Typography fontSize={12} variant="p" color="error">
          {errorText ? errorText : ""}
        </Typography>
      </Box>
    </Box>
  );
}
