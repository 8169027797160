import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  typography: {
    marginBottom: 25,
  },
});

theme = responsiveFontSizes(theme);

export default theme;
