import { Box, Typography, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function CustomSelect({
  placeholder,
  selectedValue,
  setSelectedValue,
  allValues,
  disabled,
  error,
  mb,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box width="100%" mb={mb !== undefined ? mb : 2}>
      <Select
        className={classes.selectMain}
        value={selectedValue}
        onChange={handleChange}
        defaultValue=""
        displayEmpty
        renderValue={
          selectedValue !== "" &&
          selectedValue?.label !== "" &&
          selectedValue !== `Select ${placeholder}` &&
          selectedValue?.label !== `Select ${placeholder}`
            ? undefined
            : () => placeholder
        }
        disabled={disabled}
        error={error}
      >
        {allValues?.map((value, index) => (
          <MenuItem key={index} value={value}>
            {value.label !== undefined ? value.label : value}
          </MenuItem>
        ))}
      </Select>
      {error ? (
        <Box ml={2}>
          <Typography fontSize={12} variant="p" color="error">
            {error}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  selectMain: {
    width: "100%",
  },
}));
