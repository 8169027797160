// Done
import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Container, Box, TextField, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

import userContext from "../context/userContext";
import PatientSelect from "../components/PatientSelect";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import CustomDatePicker from "../components/CustomDatePicker";
import MuiPatientsTopNavigation from "../components/MuiPatientsTopNavigation";

// const windowWidth = window.innerWidth;
// const windowHeight = window.innerHeight;
// const pdfDisplayDimension = Math.min(windowWidth, windowHeight) * 0.75;

export default function CreatePatientDocument() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const { patientId } = useParams();

  const fileInputRef = useRef(null);

  const [uploadedPdfFile, setUploadedPdfFile] = useState(null);
  const [uploadedPdfFileData, setUploadedPdfFileData] = useState(null);
  const [uploadedPdfFileBlobUrl, setUploadedPdfFileBlobUrl] = useState(null);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentIssueDate, setDocumentIssueDate] = useState(null);
  const [documentNotes, setDocumentNotes] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      toast("Invalid file.");
      return;
    }

    if (uploadedFile.type !== "application/pdf") {
      toast("Only .pdf files can be uploaded for Patient Documents.");
      return;
    }

    if (uploadedFile.size > 5_242_880) {
      toast("File size cannot be larger than 5 MB.");
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const fileData = e.target.result;
      setUploadedPdfFile(uploadedFile);
      setUploadedPdfFileData(fileData);
      setUploadedPdfFileBlobUrl(URL.createObjectURL(uploadedFile));
    };
    fileReader.readAsDataURL(uploadedFile);
  };

  const handleUploadedFilenameButtonClick = (event) => {
    window.open(uploadedPdfFileBlobUrl, "_blank", "width=600, height=400");
  };

  const handleAddPatientDocumentButtonClick = async (event) => {
    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setIsUploading(true);

    const newPatientDocument = {
      documentType: documentType,
      documentTitle: documentTitle,
      ...(documentIssueDate !== null
        ? { documentIssueDate: documentIssueDate.toISOString() }
        : {}),
      ...(documentNotes !== null ? { documentNotes: documentNotes } : {}),
    };

    const response = await apiClient.patientDocument.addNew(
      selectedPatient._id,
      newPatientDocument
    );
    if (!response.success) {
      console.log(response);
      toast(response.error.message);
      setIsUploading(false);
      return;
    }

    const newPatientDocumentId = response.result.patientDocument._id;
    const formData = new FormData();
    formData.append("file", uploadedPdfFile);
    const updateFileResponse = await apiClient.patientDocument.updateFile(
      newPatientDocumentId,
      formData
    );
    if (!updateFileResponse.success) {
      console.log(response);
      toast(
        "Patient document details added successfully but there was a problem in uploading the file."
      );
      setIsUploading(false);
      return;
    }

    toast("Patient document added successfully");
    if (patientId === undefined) {
      setSelectedPatient(null);
    }
    setDocumentType("");
    setDocumentTitle("");
    setDocumentIssueDate(null);
    setDocumentNotes("");
    setUploadedPdfFile(null);
    setUploadedPdfFileData(null);
    setValidationErrors({});
    setDisplayValidationErrors(false);
    setIsUploading(false);
  };

  useEffect(() => {
    document.title = "Add Patient Document";
  }, []);

  useEffect(() => {
    setValidationErrors(
      validators.patientDocuments.addNew(
        selectedPatient?._id,
        documentType,
        documentTitle,
        documentIssueDate,
        documentNotes,
        uploadedPdfFile
      )
    );
  }, [
    selectedPatient,
    documentType,
    documentTitle,
    documentIssueDate,
    documentNotes,
    uploadedPdfFile,
  ]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.createPatientLogPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".pdf"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />

        {patientId !== undefined ? (
          <MuiPatientsTopNavigation patientId={patientId} />
        ) : null}

        <PatientSelect
          defaultSelectedPatientId={patientId}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          disabled={isUploading}
          errorText={displayValidationErrors && validationErrors.patientId}
        />
        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Document Type"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            disabled={isUploading}
            error={
              displayValidationErrors &&
              validationErrors.documentType !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.documentType
            }
          />
        </Box>
        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Document Title"
            value={documentTitle}
            onChange={(e) => setDocumentTitle(e.target.value)}
            disabled={isUploading}
            error={
              displayValidationErrors &&
              validationErrors.documentTitle !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.documentTitle
            }
          />
        </Box>

        <CustomDatePicker
          label="Document Issue Date"
          selected={documentIssueDate}
          onChange={setDocumentIssueDate}
          onFocus={() => {}}
          disabled={isUploading}
          showTimeSelect={true}
          handleClearButtonClick={() => setDocumentIssueDate(null)}
          errorText={
            displayValidationErrors && validationErrors.documentIssueDate
          }
        />

        <Box mb={2}>
          <TextField
            className={classes.inputTextField}
            label="Document Notes"
            multiline
            rows={3}
            value={documentNotes}
            onChange={(e) => setDocumentNotes(e.target.value)}
            disabled={isUploading}
            error={
              displayValidationErrors &&
              validationErrors.documentNotes !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.documentNotes
            }
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          {uploadedPdfFile !== null ? (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Box maxWidth="45%">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleUploadedFilenameButtonClick}
                  disabled={isUploading}
                  sx={{ maxWidth: "100%" }}
                >
                  <Typography variant="p" noWrap>
                    {uploadedPdfFile.name}
                  </Typography>
                </Button>
              </Box>

              <Button
                color="primary"
                variant="outlined"
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading}
              >
                Change File
              </Button>
            </Box>
          ) : (
            <Button
              color={
                displayValidationErrors && validationErrors.documentFile
                  ? "error"
                  : "primary"
              }
              variant="outlined"
              onClick={() => fileInputRef.current.click()}
              disabled={isUploading}
            >
              No File Chosen
            </Button>
          )}
        </Box>

        <Box width="100%" display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPatientDocumentButtonClick}
            disabled={
              displayValidationErrors &&
              Object.keys(validationErrors).length > 0
            }
          >
            {isUploading ? (
              <BeatLoader color="#0000FF" loading={true} size={10} />
            ) : (
              "Add Patient Document"
            )}
          </Button>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  createPatientLogPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: "100%",
  },
}));
