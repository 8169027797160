import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-horizontal-scrolling-menu/dist/styles.css";

import Navbar from "./components/Navbar";
import Register from "./pages/Register";
import Login from "./pages/Login";
import RequestResetPassword from "./pages/RequestResetPassword";
import PerformResetPassword from "./pages/PerformResetPassword";
import DoubleAuthAttempt from "./pages/DoubleAuthAttempt";
import Settings from "./pages/Settings";
import Home from "./pages/Home";
import PatientDetails from "./pages/PatientDetails";
import UserContext from "./context/userContext";
import StateContext from "./context/stateContext";
import theme from "./ui/theme";
import SearchPatients from "./pages/SearchPatients";
import AllDocumentsOfPatient from "./pages/AllDocumentsOfPatient";
import PatientDocumentDetails from "./pages/PatientDocumentDetails";
import SearchPatientLogs from "./pages/SearchPatientLogs";
import CreatePatientLog from "./pages/CreatePatientLog";
import AllLogsOfPatient from "./pages/AllLogsOfPatient";
import PatientLogDetails from "./pages/PatientLogDetails";
import CreatePatient from "./pages/CreatePatient";
// import AllLogsOfPoint from "./pages/AllLogsOfPoint";
import apiClient from "./api/apiServices";
import { getNearestPointToLocationWithDistance } from "./utils/mathUtils";
import { saveToLocalStorage } from "./localStorage/localStorage";
import Layout from "./components/Layout";
import { parsePatientObjectForFrontend, addLabelToPatient, parsePointObjectForFrontend } from "./api/apiUtils";
import PerformSetup from "./pages/PerformSetup";
import CreatePatientDocument from "./pages/CreatePatientDocument";
import getAppSettings from "./config/appSettings";

const appVersion = "PMA - 2023-06-11 V4";

export default function App() {
  const [appSettings, setAppSettings] = useState(getAppSettings());
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  // get saved state?
  // also save the state on change?

  // Home Page State
  const [homePageErrorText, setHomePageErrorText] = useState("");
  const [homePageAreAllLogsLoaded, setHomePageAreAllLogsLoaded] = useState(false);
  const [homePagePatientLogs, setHomePagePatientLogs] = useState([]);
  const [homePageSortingKey, setHomePageSortingKey] = useState("Date & Time Desc");

  // Search Patients Page State
  const [searchPatientsPageErrorText, setSearchPatientsPageErrorText] = useState("");
  const [searchPatientsPageSearchQuery, setSearchPatientsPageSearchQuery] = useState("");
  const [searchPatientsPageSearchResults, setSearchPatientsPageSearchResults] = useState([]);
  const [searchPatientsPageSortingKey, setSearchPatientsPageSortingKey] = useState("Name");

  // Patient Details Page State
  const [patientDetailsPageOriginalPatientObject, setPatientDetailsPageOriginalPatientObject] = useState(null);

  // Search Patients Logs Page State
  const [searchPatientsLogsPageErrorText, setSearchPatientsLogsPageErrorText] = useState("");
  const [searchPatientsLogsPageSearchQuerySelectedPatient, setSearchPatientsLogsPageSearchQuerySelectedPatient] =
    useState(null);
  const [searchPatientsLogsPageSearchQuerySelectedPoint, setSearchPatientsLogsPageSearchQuerySelectedPoint] =
    useState(null);
  const [searchPatientsLogsPageSearchQueryStartDatetime, setSearchPatientsLogsPageSearchQueryStartDatetime] =
    useState(null);
  const [searchPatientsLogsPageSearchQueryEndDatetime, setSearchPatientsLogsPageSearchQueryEndDatetime] =
    useState(null);
  const [searchPatientsLogsPageSearchQueryNotes, setSearchPatientsLogsPageSearchQueryNotes] = useState("");
  const [searchPatientsLogsPageSearchQueryPrescription, setSearchPatientsLogsPageSearchQueryPrescription] =
    useState("");
  const [searchPatientsLogsPageSearchResults, setSearchPatientsLogsPageSearchResults] = useState([]);
  const [searchPatientsLogsPageSortingKey, setSearchPatientsLogsPageSortingKey] = useState("Date & Time Desc");

  // All Logs of Patient Page State
  const [allLogsOfPatientPageErrorText, setAllLogsOfPatientPageErrorText] = useState("");
  const [allLogsOfPatientPagePatientDetails, setAllLogsOfPatientPagePatientDetails] = useState("");
  const [allLogsOfPatientPagePatientLogs, setAllLogsOfPatientPagePatientLogs] = useState([]);
  const [allLogsOfPatientPageSortingKey, setAllLogsOfPatientPageSortingKey] = useState("Date & Time Desc");

  // All Docs of Patient Page State
  const [allDocsOfPatientPageErrorText, setAllDocsOfPatientPageErrorText] = useState("");
  const [allDocsOfPatientPagePatientDetails, setAllDocsOfPatientPagePatientDetails] = useState("");
  const [allDocsOfPatientPagePatientDocs, setAllDocsOfPatientPagePatientDocs] = useState([]);
  const [allDocsOfPatientPageSortingKey, setAllDocsOfPatientPageSortingKey] = useState("Title");

  const [allPoints, setAllPoints] = useState([]);
  const [currentPoint, setCurrentPoint] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("currentPoint"));
    } catch (e) {}

    return null;
  });

  const [patientsLocalCache, setPatientsLocalCache] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("patientsLocalCache"));
    } catch (e) {}

    return null;
  });

  const getAllPointsFromApi = async () => {
    const response = await apiClient.point.getAll();

    if (!response.success) {
      console.log(response);
      return;
    }

    response.result.points = response.result.points.map((point) => parsePointObjectForFrontend(point));
    setAllPoints(response.result.points);
    setCurrentPoint(response.result.points[0]);
    localStorage.setItem("currentPoint", JSON.stringify(response.result.points[0]));
  };

  const updatePatientsLocalCache = async () => {
    console.log("Updating patients local cache.");

    let timestamp = 1;
    let updatedPatientsLocalCache;

    if (patientsLocalCache !== null) {
      updatedPatientsLocalCache = { ...patientsLocalCache };
      timestamp = patientsLocalCache.timestamp;
    } else {
      updatedPatientsLocalCache = {
        patients: [],
      };
    }

    const response = await apiClient.patient.getAllUpdatedSince(timestamp);
    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      return;
    }

    response.result.patients = response.result.patients.map((patient) => parsePatientObjectForFrontend(patient));

    // De dupe
    // Collect ids of newly fetched
    // Delete objects with those ids from current
    // Concat newly fetched to current
    const newFetchedPatientIds = response.result.patients.map((patient) => patient._id);
    updatedPatientsLocalCache.patients = updatedPatientsLocalCache.patients.filter(
      (patient) => !newFetchedPatientIds.includes(patient._id)
    );
    updatedPatientsLocalCache.patients = updatedPatientsLocalCache.patients.concat(response.result.patients);

    updatedPatientsLocalCache.patients.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

    // Add label to each patient: Label = name + last seen at point name
    for (let i = 0; i < updatedPatientsLocalCache.patients.length; i++) {
      updatedPatientsLocalCache.patients[i] = addLabelToPatient(updatedPatientsLocalCache.patients[i]);
    }

    updatedPatientsLocalCache.timestamp = new Date().getTime();

    setPatientsLocalCache(updatedPatientsLocalCache);
  };

  useEffect(() => {
    const userChangedEventListener = () => {
      setUser(JSON.parse(localStorage.getItem("user")));
      const updatedUser = JSON.parse(localStorage.getItem("user"));
      if (updatedUser !== null) {
        getAllPointsFromApi();
        updatePatientsLocalCache();
      }
    };

    window.addEventListener("userChanged", userChangedEventListener);

    const updatedUser = JSON.parse(localStorage.getItem("user"));
    if (updatedUser !== null) {
      getAllPointsFromApi();
      updatePatientsLocalCache();
    }

    return () => {
      window.removeEventListener("userChanged", userChangedEventListener);
    };
  }, []);

  useEffect(() => {
    const savedAppVersion = JSON.parse(localStorage.getItem("appVersion"));
    if (savedAppVersion !== appVersion) {
      localStorage.clear();
      localStorage.setItem("appVersion", JSON.stringify(appVersion));

      if (user !== null) {
        setUser(null);
        window.dispatchEvent(new Event("userChanged"));
        toast("The application has been updated. Please Log In again.");
      }
    }
  }, [user]);

  useEffect(() => {
    saveToLocalStorage("patientsLocalCache", patientsLocalCache);
  }, [patientsLocalCache]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        appSettings,
        setAppSettings,
        currentPoint,
        setCurrentPoint,
        allPoints,
        patientsLocalCache,
        updatePatientsLocalCache,
      }}
    >
      <StateContext.Provider
        value={{
          // Home Page
          homePageErrorText,
          setHomePageErrorText,
          homePageAreAllLogsLoaded,
          setHomePageAreAllLogsLoaded,
          homePagePatientLogs,
          setHomePagePatientLogs,
          homePageSortingKey,
          setHomePageSortingKey,

          // Search Patients Page
          searchPatientsPageErrorText,
          setSearchPatientsPageErrorText,
          searchPatientsPageSearchQuery,
          setSearchPatientsPageSearchQuery,
          searchPatientsPageSearchResults,
          setSearchPatientsPageSearchResults,
          searchPatientsPageSortingKey,
          setSearchPatientsPageSortingKey,

          // Patient Details Page
          patientDetailsPageOriginalPatientObject,
          setPatientDetailsPageOriginalPatientObject,

          // Search Patient Logs Page
          searchPatientsLogsPageErrorText,
          setSearchPatientsLogsPageErrorText,
          searchPatientsLogsPageSearchQuerySelectedPatient,
          setSearchPatientsLogsPageSearchQuerySelectedPatient,
          searchPatientsLogsPageSearchQuerySelectedPoint,
          setSearchPatientsLogsPageSearchQuerySelectedPoint,
          searchPatientsLogsPageSearchQueryStartDatetime,
          setSearchPatientsLogsPageSearchQueryStartDatetime,
          searchPatientsLogsPageSearchQueryEndDatetime,
          setSearchPatientsLogsPageSearchQueryEndDatetime,
          searchPatientsLogsPageSearchQueryNotes,
          setSearchPatientsLogsPageSearchQueryNotes,
          searchPatientsLogsPageSearchQueryPrescription,
          setSearchPatientsLogsPageSearchQueryPrescription,
          searchPatientsLogsPageSearchResults,
          setSearchPatientsLogsPageSearchResults,
          searchPatientsLogsPageSortingKey,
          setSearchPatientsLogsPageSortingKey,

          // All Logs Of Patient Page
          allLogsOfPatientPageErrorText,
          setAllLogsOfPatientPageErrorText,
          allLogsOfPatientPagePatientDetails,
          setAllLogsOfPatientPagePatientDetails,
          allLogsOfPatientPagePatientLogs,
          setAllLogsOfPatientPagePatientLogs,
          allLogsOfPatientPageSortingKey,
          setAllLogsOfPatientPageSortingKey,

          // All Docs of Patient Page
          allDocsOfPatientPageErrorText,
          setAllDocsOfPatientPageErrorText,
          allDocsOfPatientPagePatientDetails,
          setAllDocsOfPatientPagePatientDetails,
          allDocsOfPatientPagePatientDocs,
          setAllDocsOfPatientPagePatientDocs,
          allDocsOfPatientPageSortingKey,
          setAllDocsOfPatientPageSortingKey,
        }}
      >
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <Router>
            <Layout>
              <Routes>
                <Route path="/auth/register" element={<Register />} />
                <Route path="/auth/login" element={<Login />} />
                <Route path="/auth/double-auth-attempt" element={<DoubleAuthAttempt />} />
                <Route path="/auth/perform-setup/:setupAccountToken" element={<PerformSetup />} />
                <Route path="/auth/request-reset-password" element={<RequestResetPassword />} />
                <Route path="/auth/perform-reset-password/:resetPasswordToken" element={<PerformResetPassword />} />
                <Route path="/" element={<Home />} />
                <Route path="/settings" element={<Settings />} />

                {/* Patients */}
                <Route path="/patients/search" element={<SearchPatients />} />
                <Route path="/patients/add" element={<CreatePatient />} />
                <Route path="/patients/:patientId" element={<PatientDetails />} />

                {/* Patient Documents */}
                <Route path="/patient-documents/add/:patientId?" element={<CreatePatientDocument />} />
                <Route path="/patient-documents/:patientDocumentId" element={<PatientDocumentDetails />} />
                <Route path="/patient-documents/for-patient/:patientId" element={<AllDocumentsOfPatient />} />

                {/* Patient Logs */}
                <Route path="/patient-logs/search" element={<SearchPatientLogs />} />
                <Route path="/patient-logs/add/:patientId?" element={<CreatePatientLog />} />
                <Route path="/patient-logs/:patientLogId" element={<PatientLogDetails />} />
                <Route path="/patient-logs/for-patient/:patientId" element={<AllLogsOfPatient />} />
              </Routes>
            </Layout>
          </Router>
        </ThemeProvider>
      </StateContext.Provider>
    </UserContext.Provider>
  );
}
