import { Box, Typography } from "@mui/material";

export default function SinglePointDisplayer({ point }) {
  return (
    <Box
      maxWidth="sm"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      mb={2}
    >
      <Box width="70%">
        <Typography>{`Location: ${point?.name}`}</Typography>
      </Box>
    </Box>
  );
}
