import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Avatar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ExpandMore as ExpandMoreIcon,
  Crop as CropIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import Select from "../components/Select";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomTextField from "../components/CustomTextField";
import gradients from "../ui/gradients";

const patientImageDiameter =
  window.innerWidth < window.innerHeight
    ? window.innerWidth * 0.5
    : window.innerHeight * 0.33;

export default function CreatePatient() {
  const classes = useStyles();
  const { user } = useContext(userContext);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [uploadedImageFileData, setUploadedImageFileData] = useState(null);

  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setGender] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [dateOfDemise, setDateOfDemise] = useState(null);

  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "image/jpeg") {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileData = e.target.result;
        setUploadedImageFile(uploadedFile);
        setUploadedImageFileData(fileData);
      };
      fileReader.readAsDataURL(uploadedFile);
    }
  };

  const handleAddPatientButtonClick = async (event) => {
    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setIsUploading(true);

    // first create the patient
    const patient = {
      name: name,
      dateOfBirth: dateOfBirth,
      gender: gender,
    };
    if (
      bloodGroup !== null &&
      bloodGroup !== "" &&
      bloodGroup !== "Select Blood Group"
    ) {
      patient.bloodGroup = bloodGroup;
    }
    if (dateOfDemise !== null) {
      patient.dateOfDemise = dateOfDemise;
    }

    setIsUploading(true);

    const createPatientResponse = await apiClient.patient.addNew(patient);
    if (!createPatientResponse.success) {
      console.log(createPatientResponse);
      toast(createPatientResponse.error.message);
      setIsUploading(false);
      return;
    }

    const newPatientId = createPatientResponse.result.patient._id;

    // then, add the image
    const formData = new FormData();
    formData.append("image", uploadedImageFile);
    const updateImageResponse = await apiClient.patient.updateImage(
      newPatientId,
      formData
    );
    if (!updateImageResponse.success) {
      console.log(updateImageResponse);
      toast("Patient added successfully, but failed to upload image.");
      setIsUploading(false);
      navigate(`/patients/${newPatientId}`);
      return;
    }

    toast("Patient added successfully.");

    setUploadedImageFile(null);
    setUploadedImageFileData(null);
    setName("");
    setDateOfBirth(null);
    setGender("");
    setBloodGroup("");
    setDateOfDemise(null);
    setDisplayValidationErrors(false);
    setValidationErrors({});

    setIsUploading(false);
  };

  useEffect(() => {
    document.title = "Add Patient";
  }, []);

  useEffect(() => {
    setValidationErrors(
      validators.patients.addNew(
        name,
        dateOfBirth,
        gender,
        bloodGroup,
        dateOfDemise,
        uploadedImageFile
      )
    );
  }, [name, dateOfBirth, gender, bloodGroup, dateOfDemise, uploadedImageFile]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.createPatientPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />

        <Grid
          container
          spacing={{
            xs: 0,
            sm: 2,
          }}
          mb={4}
        >
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} mb={3}>
            <CustomTextField
              label="Name"
              type="text"
              disabled={isUploading}
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={displayValidationErrors && validationErrors.name}
              helperText={displayValidationErrors && validationErrors.name}
            />

            <CustomDatePicker
              label="Date of Birth"
              selected={dateOfBirth}
              onChange={setDateOfBirth}
              disabled={isUploading}
              showTimeSelect={true}
              handleClearButtonClick={() => setDateOfBirth(null)}
              errorText={
                displayValidationErrors && validationErrors.dateOfBirth
              }
            />

            <Select
              placeholder="Gender"
              allValues={["Male", "Female", "Other"]}
              disabled={isUploading}
              selectedValue={gender}
              setSelectedValue={(newValue) => setGender(newValue)}
              error={displayValidationErrors && validationErrors.gender}
            />

            <Select
              placeholder="Blood Group"
              allValues={[
                "Select Blood Group",
                "A+",
                "A-",
                "B+",
                "B-",
                "O+",
                "O-",
                "AB+",
                "AB-",
              ]}
              disabled={isUploading}
              selectedValue={bloodGroup}
              setSelectedValue={(newValue) => setBloodGroup(newValue)}
              error={displayValidationErrors && validationErrors.bloodGroup}
            />

            <Accordion width="100%" sx={{ backgroundColor: "inherit" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Additional Fields</Typography>
              </AccordionSummary>
              <AccordionDetails width="100%">
                <CustomDatePicker
                  label="Date of Demise"
                  selected={dateOfDemise}
                  onChange={setDateOfDemise}
                  disabled={isUploading}
                  showTimeSelect={true}
                  handleClearButtonClick={() => setDateOfDemise(null)}
                  errorText={
                    displayValidationErrors && validationErrors.dateOfDemise
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            mb={3}
          >
            <Avatar
              className={classes.profileImage}
              src={uploadedImageFileData}
              sx={{
                width: patientImageDiameter,
                height: patientImageDiameter,
                backgroundColor:
                  displayValidationErrors && validationErrors.imageFile
                    ? "red"
                    : "#ADD8E6",
              }}
            />

            {displayValidationErrors && validationErrors.imageFile ? (
              <Typography fontSize={12} variant="p" color="error">
                {validationErrors.imageFile}
              </Typography>
            ) : null}

            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                color="primary"
                variant="contained"
                onClick={() => fileInputRef.current.click()}
                disabled={isUploading}
              >
                {uploadedImageFileData === null ? <AddIcon /> : <EditIcon />}
              </IconButton>

              {uploadedImageFileData !== null ? (
                <IconButton
                  color="primary"
                  variant="contained"
                  disabled={isUploading}
                  // onClick={() => {}}
                >
                  <CropIcon />
                </IconButton>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
          mb={2}
        >
          <Tooltip
            title={
              displayValidationErrors &&
              Object.keys(validationErrors).length > 0 &&
              "Validation Errors"
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isUploading ||
                  (displayValidationErrors &&
                    Object.keys(validationErrors).length > 0)
                }
                onClick={handleAddPatientButtonClick}
              >
                {isUploading ? (
                  <BeatLoader color="black" loading={true} size={10} />
                ) : (
                  "Add Patient"
                )}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  createPatientPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: gradients.createPatientPageContainer,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  profileImage: {
    display: "block",
    width: 300,
    height: 300,
    borderRadius: 150,
  },
}));
