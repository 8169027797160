// Done
import { useContext, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";

import userContext from "../context/userContext";
import {
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../localStorage/localStorage";
import { objectDeepEqual } from "../utils/jsUtils";
import { appName } from "../config/appConfig";

const Settings = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, appSettings, setAppSettings } = useContext(userContext);

  const [updatedAppSettings, setUpdatedAppSettings] = useState({
    currentPointUpdateMode: appSettings.currentPointUpdateMode,
    setCurrentPointUpdateModeToAutoOnStartup:
      appSettings.setCurrentPointUpdateModeToAutoOnStartup,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleResetPasswordButtonClick = (event) => {
    event.preventDefault();

    navigate("/auth/request-reset-password");
  };

  const handleLogoutButtonClick = async (event) => {
    event.preventDefault();
    deleteFromLocalStorage("user");
    window.dispatchEvent(new Event("userChanged"));
    deleteFromLocalStorage("tokens");
    navigate("/auth/login");
  };

  const handleSaveAppSettingsButtonClick = async (event) => {
    setAppSettings(updatedAppSettings);
    saveToLocalStorage("appSettings", updatedAppSettings);
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    document.title = `${appName} - Settings`;
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(!objectDeepEqual(appSettings, updatedAppSettings));
  }, [updatedAppSettings]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.settingsPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        {/* <Box
          width="100%"
          mb={5}
          borderRadius={5}
          sx={{
            boxShadow: 4,
            backgroundImage: "linear-gradient(#cfd4ce, #faf9f4, #e9f4f7)",
          }}
        > */}
          <Box height={25} />
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mb={2}
            mt={2}
          >
            <Typography
              className={classes.heading}
              variant="h4"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              User Settings
            </Typography>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
            p={1}
          >
            <Typography variant="h6">
              <strong>Name:</strong> {user?.name}
            </Typography>
            <Typography variant="h6">
              <strong>Email:</strong> {user?.email}
            </Typography>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            mb={2}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleResetPasswordButtonClick}
            >
              Reset Password
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={handleLogoutButtonClick}
            >
              Logout
            </Button>
          </Box>

          <Box height={25} />
        {/* </Box> */}
{/* 
        <Box
          width="100%"
          mb={5}
          borderRadius={5}
          sx={{
            boxShadow: 4,
            backgroundImage: "linear-gradient(#e9f4f7, #faf9f4, #cfd4ce)",
          }}
        >
          <Box height={25} />
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mb={2}
            mt={2}
          >
            <Typography
              className={classes.heading}
              variant="h4"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              App Settings
            </Typography>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            mb={2}
            p={1}
          >
            <Box width="50%" mr={1}>
              <Typography variant="h6">Location Update Mode:</Typography>
            </Box>

            <Box width="50%">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        updatedAppSettings.currentPointUpdateMode === "auto"
                      }
                      onChange={(e) => {
                        const changedAppSettings = {
                          ...updatedAppSettings,
                          currentPointUpdateMode: e.target.checked
                            ? "auto"
                            : "manual",
                        };
                        setUpdatedAppSettings(changedAppSettings);
                      }}
                    />
                  }
                  label={updatedAppSettings.currentPointUpdateMode}
                />
              </FormGroup>
            </Box>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            mb={2}
            p={1}
          >
            <Box width="50%" mr={1}>
              <Typography variant="h6">
                Set Location Update Mode to <em>auto</em> on startup:
              </Typography>
            </Box>

            <Box width="50%">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        updatedAppSettings.setCurrentPointUpdateModeToAutoOnStartup
                      }
                      onChange={(e) => {
                        const changedAppSettings = {
                          ...updatedAppSettings,
                          setCurrentPointUpdateModeToAutoOnStartup:
                            e.target.checked,
                        };
                        setUpdatedAppSettings(changedAppSettings);
                      }}
                      disabled={
                        updatedAppSettings.currentPointUpdateMode === "auto"
                      }
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            mb={2}
            mt={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAppSettingsButtonClick}
              disabled={!hasUnsavedChanges}
            >
              {hasUnsavedChanges ? "Save Settings" : "Saved !"}
            </Button>
          </Box>

          <Box height={25} />
        </Box> */}
      </Container>
    </div>
  );
};

export default Settings;

const useStyles = makeStyles((theme) => ({
  settingsPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
}));
