import { useState, useContext, useEffect } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import PasswordInput from "../components/PasswordInput";

export default function PerformSetup() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const { setupAccountToken } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenError, setTokenError] = useState(undefined);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const verifySetupAccountToken = async () => {
    setIsLoading(true);

    const response = await apiClient.user.verifyAccountSetupToken(
      setupAccountToken
    );
    if (!response.success) {
      setIsTokenValid(false);
      switch (response.error.errorCode) {
        case 5: {
          setTokenError("Link already used.");
          break;
        }
        case 7: {
          setTokenError("Invalid Link.");
          break;
        }
        default: {
          break;
        }
      }

      setIsLoading(false);
      return;
    }

    setEmail(response.result.userDetails.email);
    setIsTokenValid(true);
    setIsLoading(false);
  };

  const handleCreateAccountButtonClick = async (event) => {
    event.preventDefault();

    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setIsUploading(true);

    const response = await apiClient.user.performAccountSetup(
      setupAccountToken,
      name,
      password
    );
    if (!response.success) {
      console.log(response);
      toast(response.error.message);
      setIsUploading(false);
      return;
    }

    toast(
      "Your account has been set up successfully. Redirecting to the Login Page."
    );
    setTimeout(() => {
      navigate("/auth/login");
    }, 5000);
    setIsUploading(false);
  };

  useEffect(() => {
    verifySetupAccountToken();
  }, []);

  useEffect(() => {
    setValidationErrors(
      validators.user.setupAccount(name, password, confirmPassword)
    );
  }, [name, password, confirmPassword]);

  if (user) {
    return <Navigate to="/auth/double-auth-attempt" />;
  }

  if (isLoading) {
    return (
      <Container maxWidth="md" className={classes.container}>
        <Box display="flex" justifyContent="center" mb={2}>
          <Skeleton width={500} height={75} />
        </Box>

        <Box display="flex" justifyContent="center" mb={2}>
          <Skeleton width={300} height={50} />
        </Box>

        <Box display="flex" justifyContent="center" mb={2}>
          <Skeleton width={300} height={50} />
        </Box>

        <Box display="flex" justifyContent="center" mb={2}>
          <Skeleton width={300} height={50} />
        </Box>
      </Container>
    );
  }

  if (!isTokenValid) {
    return (
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4" width="100%" textAlign="center" color="error">
          {tokenError}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h3">Setup Your Account</Typography>
      </Box>

      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h5">{email}</Typography>
      </Box>

      <Box display="flex" justifyContent="center" mb={0}>
        <TextField
          className={classes.inputTextField}
          type="text"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          error={displayValidationErrors && validationErrors.name !== undefined}
          helperText={displayValidationErrors && validationErrors.name}
        />
      </Box>

      <Box display="flex" justifyContent="center" mb={2}>
        <PasswordInput
          className={classes.inputTextField}
          type="password"
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          error={
            displayValidationErrors && validationErrors.password !== undefined
          }
          helperText={displayValidationErrors && validationErrors.password}
        />
      </Box>

      <Box display="flex" justifyContent="center" mb={2}>
        <PasswordInput
          className={classes.inputTextField}
          type="password"
          label="Confirm password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          error={
            displayValidationErrors &&
            validationErrors.confirmPassword !== undefined
          }
          helperText={
            displayValidationErrors && validationErrors.confirmPassword
          }
        />
      </Box>

      <Box display="flex" justifyContent="center" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateAccountButtonClick}
          disabled={isUploading}
        >
          {isUploading ? (
            <BeatLoader color="white" loading={true} size={10} />
          ) : (
            "Create Account"
          )}
        </Button>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
}));
