import { useState, useEffect, useContext, useRef } from "react";
import {
  useNavigate,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Avatar,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ExpandMore as ExpandMoreIcon,
  Crop as CropIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

import CustomDatePicker from "../components/CustomDatePicker";
import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import { parsePatientObjectForFrontend } from "../api/apiUtils";
import validators from "../api/validators";
import Select from "../components/Select";
import { patientDeleteAllowedTimeInHours } from "../config/appConfig";
import gradients from "../ui/gradients";
import CustomTextField from "../components/CustomTextField";
import ConfirmDialog from "../components/ConfirmDialog";
import MuiPatientsTopNavigation from "../components/MuiPatientsTopNavigation";
import localStorageKeys from "../localStorage/keys";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../localStorage/localStorage";
import stateContext from "../context/stateContext";

const patientImageDiameter =
  window.innerWidth < window.innerHeight
    ? window.innerWidth * 0.5
    : window.innerHeight * 0.33;

export default function PatientDetails() {
  const classes = useStyles();
  const { user } = useContext(userContext);
  const {
    patientDetailsPageOriginalPatientObject,
    setPatientDetailsPageOriginalPatientObject,
  } = useContext(stateContext);
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== "default";
  const { patientId } = useParams();
  const fileInputRef = useRef(null);

  // const [patientDetailsPageOriginalPatientObject, setPatientDetailsPageOriginalPatientObject] = useState(null);

  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [uploadedImageFileData, setUploadedImageFileData] = useState(null);

  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setGender] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [dateOfDemise, setDateOfDemise] = useState(null);

  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [getDetailsErrorTitle, setGetDetailsErrorTitle] = useState("");
  const [getDetailsErrorSubtitle, setGetDetailsErrorSubtitle] = useState("");
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "image/jpeg") {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const fileData = e.target.result;
        setUploadedImageFile(uploadedFile);
        setUploadedImageFileData(fileData);
      };
      fileReader.readAsDataURL(uploadedFile);
    }
  };

  const loadStateFromObject = (patient) => {
    setName(patient.name);
    setDateOfBirth(new Date(patient.dateOfBirth));
    setGender(patient.gender);
    if (patient.bloodGroup !== undefined) {
      setBloodGroup(patient.bloodGroup);
    }
    if (patient.dateOfDemise === null || patient.dateOfDemise === undefined) {
      setDateOfDemise(null);
    } else {
      setDateOfDemise(new Date(patient.dateOfDemise));
    }

    if (patient.imageUrl !== undefined) {
      setUploadedImageFile({
        name: `${patient.name}.jpg`,
        original: true,
      });
      setUploadedImageFileData(patient.imageUrl);
    }

    patient.createdAt = new Date(patient?.createdAt);
    patient.updatedAt = new Date(patient?.updatedAt);

    setCanBeDeleted(
      Math.abs(new Date() - patient?.createdAt) / 36e5 <
        patientDeleteAllowedTimeInHours
    );
  };

  const getDetailsFromApi = async (updateLoadingStatus = true) => {
    console.log("Patient Details Page: Calling API");

    if (updateLoadingStatus) {
      setIsLoading(true);
    }

    const response = await apiClient.patient.getDetail(patientId);
    if (!response.success) {
      console.log(response);
      if (response.error.validationErrors) {
        setGetDetailsErrorTitle("Invalid Patient URL");
        setGetDetailsErrorSubtitle(
          "It looks like you have entered an invalid URL for a Patient."
        );
      } else {
        setGetDetailsErrorTitle("Failed to fetch Patient");
        setGetDetailsErrorSubtitle("Refresh the page to try again.");
      }

      setIsLoading(false);
      setIsLoadingFirstTime(false);
      return;
    }

    response.result.patient = parsePatientObjectForFrontend(
      response.result.patient
    );

    setPatientDetailsPageOriginalPatientObject(response.result.patient);
    loadStateFromObject(response.result.patient);
    setIsLoading(false);
    setIsLoadingFirstTime(false);
    // saveCurrentState();
  };

  const handleSaveButtonClick = async (event) => {
    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setIsUploading(true);

    const toUpdateDetails =
      name !== patientDetailsPageOriginalPatientObject.name ||
      dateOfBirth !== patientDetailsPageOriginalPatientObject.dateOfBirth ||
      gender !== patientDetailsPageOriginalPatientObject.gender ||
      bloodGroup !== patientDetailsPageOriginalPatientObject.bloodGroup ||
      dateOfDemise !== patientDetailsPageOriginalPatientObject.dateOfDemise;
    const toUpdateFile =
      uploadedImageFileData !== null && !uploadedImageFile.original;

    let updatedPatientObject = patientDetailsPageOriginalPatientObject;

    // File update goes first since we have to get the updated patient document
    if (toUpdateFile) {
      const formData = new FormData();
      formData.append("image", uploadedImageFile);
      const updateImageResponse = await apiClient.patient.updateImage(
        patientId,
        formData
      );

      if (!updateImageResponse.success) {
        console.log(updateImageResponse);
        toast(updateImageResponse.error.message);
        setIsUploading(false);
        return;
      }

      updatedPatientObject = parsePatientObjectForFrontend(
        updateImageResponse.result.patient
      );
    }

    if (toUpdateDetails) {
      const updatedPatient = {
        name: name,
        dateOfBirth: dateOfBirth,
        gender: gender,
      };
      if (bloodGroup !== "" && bloodGroup !== "Select Blood Group") {
        updatedPatient.bloodGroup = bloodGroup;
      }
      if (dateOfDemise !== null) {
        updatedPatient.dateOfDemise = dateOfDemise;
      }

      const updateDetailsResponse = await apiClient.patient.update(
        patientId,
        updatedPatient
      );
      if (!updateDetailsResponse.success) {
        toast(updateDetailsResponse.error.message);
        console.log(updateDetailsResponse);
        setIsUploading(false);
        return;
      }

      updatedPatientObject = parsePatientObjectForFrontend(
        updateDetailsResponse.result.patient
      );
    }

    if (toUpdateFile || toUpdateDetails) {
      setPatientDetailsPageOriginalPatientObject(updatedPatientObject);
      loadStateFromObject(updatedPatientObject);
    }

    setIsInEditMode(false);
    setIsUploading(false);
  };

  const handleDiscardButtonClick = (event) => {
    event.preventDefault();

    if (!hasUnsavedChanges) {
      setIsInEditMode(false);
      return;
    }

    loadStateFromObject(patientDetailsPageOriginalPatientObject);

    setIsInEditMode(false);
    setHasUnsavedChanges(false);
  };

  const handleEditButtonClick = (event) => {
    setIsInEditMode(true);
  };

  const deleteObject = async () => {
    setIsUploading(true);

    const response = await apiClient.patient.delete(patientId);
    if (!response.success) {
      console.log(response);
      toast(response.error.message);
      setIsUploading(false);
      return;
    }

    toast("Patient deleted successfully.");
    setIsUploading(false);

    setTimeout(() => {
      if (doesAnyHistoryEntryExist) {
        navigate(-1);
      } else {
        navigate("/");
      }
    }, 2000);
  };

  const handleDeleteButtonClick = async (event) => {
    setDeleteConfirmDialogOpen(true);
  };

  useEffect(() => {
    document.title = "Patient Details";

    console.log(patientDetailsPageOriginalPatientObject);

    if (
      patientDetailsPageOriginalPatientObject === null ||
      patientDetailsPageOriginalPatientObject._id !== patientId
    ) {
      getDetailsFromApi();
    } else {
      loadStateFromObject(patientDetailsPageOriginalPatientObject);
    }
  }, []);

  useEffect(() => {
    setValidationErrors(
      validators.patients.addNew(
        name,
        dateOfBirth,
        gender,
        bloodGroup,
        dateOfDemise,
        uploadedImageFile
      )
    );

    setHasUnsavedChanges(
      name !== patientDetailsPageOriginalPatientObject?.name ||
        dateOfBirth !== patientDetailsPageOriginalPatientObject?.dateOfBirth ||
        gender !== patientDetailsPageOriginalPatientObject?.gender ||
        bloodGroup !== patientDetailsPageOriginalPatientObject?.bloodGroup ||
        dateOfDemise !==
          patientDetailsPageOriginalPatientObject?.dateOfDemise ||
        (uploadedImageFileData !== null && !uploadedImageFile.original)
    );
  }, [name, dateOfBirth, gender, bloodGroup, dateOfDemise, uploadedImageFile]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoading) {
    return (
      <div className={classes.patientDetailsPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <MuiPatientsTopNavigation patientId={patientId} />
          <Box mb={8} />
          <Grid
            container
            spacing={{
              xs: 0,
              sm: 2,
            }}
            mb={4}
          >
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} mb={3}>
              <Skeleton style={{ height: 50, marginBottom: 20 }} count={5} />
            </Grid>

            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} mb={3}>
              <Box width="100%" display="flex" justifyContent="center" mb={0}>
                <Skeleton
                  circle={true}
                  style={{
                    height: patientImageDiameter,
                    width: patientImageDiameter,
                    marginBottom: 20,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  if (getDetailsErrorTitle !== "") {
    return (
      <div className={classes.patientDetailsPageContainer}>
        <Container maxWidth="md" className={classes.container}>
          <Typography color="error" variant="h3">
            {getDetailsErrorTitle}
          </Typography>
          <Typography color="error" variant="p">
            {getDetailsErrorSubtitle}
          </Typography>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.patientDetailsPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <input
          ref={fileInputRef}
          type="file"
          accept=".jpg,.jpeg"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <ConfirmDialog
          open={deleteConfirmDialogOpen}
          onClose={() => setDeleteConfirmDialogOpen(false)}
          title="Delete Patient?"
          contentText="Are you sure you want to delete this Patient? A deleted Patient cannot be recovered."
          onAgree={() => deleteObject()}
          onDisagree={() => setDeleteConfirmDialogOpen(false)}
          color="error"
        />

        <MuiPatientsTopNavigation patientId={patientId} />

        {isInEditMode ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {isUploading ? (
                <Box mr={2}>
                  <ClipLoader color={"#0000FF"} loading={true} size={25} />
                </Box>
              ) : null}
              <Box mr={2}>
                <Tooltip
                  title={
                    !hasUnsavedChanges
                      ? "No changes have been made."
                      : displayValidationErrors &&
                        Object.keys(validationErrors).length > 0
                      ? "Validation Errors"
                      : ""
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveButtonClick}
                      disabled={
                        isUploading ||
                        !hasUnsavedChanges ||
                        (displayValidationErrors &&
                          Object.keys(validationErrors).length > 0)
                      }
                    >
                      Save
                    </Button>
                  </span>
                </Tooltip>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDiscardButtonClick}
                  disabled={isUploading}
                >
                  {hasUnsavedChanges ? "Discard" : "Stop Editing"}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box>
              <IconButton
                color="warning"
                onClick={() => {
                  setIsLoadingFirstTime(true);
                  getDetailsFromApi();
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditButtonClick}
                >
                  Edit
                </Button>
              </Box>

              <Box>
                <Tooltip
                  title={
                    canBeDeleted &&
                    `A patient can only be deleted up till ${patientDeleteAllowedTimeInHours} hours after creation.`
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleDeleteButtonClick}
                      disabled={!canBeDeleted || isUploading}
                    >
                      Delete
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        )}

        <Grid
          container
          spacing={{
            xs: 0,
            sm: 2,
          }}
          mb={4}
        >
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} mb={3}>
            <CustomTextField
              label="Name"
              type="text"
              disabled={!isInEditMode || isUploading}
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={displayValidationErrors && validationErrors.name}
              helperText={displayValidationErrors && validationErrors.gender}
            />

            <CustomDatePicker
              label="Date of Birth"
              selected={dateOfBirth}
              onChange={setDateOfBirth}
              disabled={!isInEditMode || isUploading}
              showTimeSelect={true}
              handleClearButtonClick={() => setDateOfBirth(null)}
              errorText={
                displayValidationErrors && validationErrors.dateOfBirth
              }
            />

            <Select
              placeholder="Gender"
              allValues={["Male", "Female", "Other"]}
              disabled={!isInEditMode || isUploading}
              selectedValue={gender}
              setSelectedValue={(newValue) => setGender(newValue)}
              error={displayValidationErrors && validationErrors.gender}
            />

            <Select
              placeholder="Blood Group"
              allValues={[
                "Select Blood Group",
                "A+",
                "A-",
                "B+",
                "B-",
                "O+",
                "O-",
                "AB+",
                "AB-",
              ]}
              disabled={!isInEditMode || isUploading}
              selectedValue={bloodGroup}
              setSelectedValue={(newValue) => setBloodGroup(newValue)}
              error={displayValidationErrors && validationErrors.bloodGroup}
            />

            <Accordion width="100%" sx={{ backgroundColor: "inherit" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Additional Fields</Typography>
              </AccordionSummary>
              <AccordionDetails width="100%">
                <CustomDatePicker
                  label="Date of Demise"
                  selected={dateOfDemise}
                  onChange={setDateOfDemise}
                  disabled={!isInEditMode || isUploading}
                  showTimeSelect={true}
                  handleClearButtonClick={() => setDateOfDemise(null)}
                  errorText={
                    displayValidationErrors && validationErrors.dateOfDemise
                  }
                />
              </AccordionDetails>
            </Accordion>

            {patientDetailsPageOriginalPatientObject?.updatedBy?.name ? (
              <Box mt={2}>
                <Typography fontSize={12} variant="p">
                  {"Patient Details last updated by "}
                </Typography>

                <Typography fontSize={12} variant="p" color="primary">
                  {`${patientDetailsPageOriginalPatientObject.updatedBy.name} `}
                </Typography>

                <Typography fontSize={12} variant="p">
                  {"at "}
                </Typography>

                <Typography fontSize={12} variant="p" color="primary">
                  {new Date(patientDetailsPageOriginalPatientObject?.updatedAt)
                    .toString()
                    .slice(0, 24)}
                </Typography>
              </Box>
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            mb={3}
          >
            <Avatar
              className={classes.profileImage}
              src={uploadedImageFileData}
              sx={{
                width: patientImageDiameter,
                height: patientImageDiameter,
                backgroundColor:
                  displayValidationErrors && validationErrors.imageFile
                    ? "red"
                    : "#ADD8E6",
              }}
            />
            {displayValidationErrors && validationErrors.imageFile ? (
              <Typography fontSize={12} variant="p" color="error">
                {validationErrors.imageFile}
              </Typography>
            ) : null}
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                color="primary"
                variant="contained"
                onClick={() => fileInputRef.current.click()}
                disabled={!isInEditMode || isUploading}
              >
                {uploadedImageFileData === null ? <AddIcon /> : <EditIcon />}
              </IconButton>

              {!uploadedImageFile?.original ? (
                <IconButton
                  color="primary"
                  variant="contained"
                  disabled={!isInEditMode || isUploading}
                  // onClick={() => {}}
                >
                  <CropIcon />
                </IconButton>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  patientDetailsPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: gradients.patientDetailsPageContainer,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  profileImage: {
    display: "block",
    width: patientImageDiameter,
    height: patientImageDiameter,
    borderRadius: patientImageDiameter / 2,
  },
}));
