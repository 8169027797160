import { useState, useContext, useEffect } from "react";
import { TextField, Button, Typography, Container, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import { Navigate } from "react-router-dom";

import userContext from "../context/userContext";
import apiServicesClient from "../api/apiServices";

const RequestResetPassword = () => {
  const classes = useStyles();
  const { user } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const sendResetPasswordEmailButtonOnClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    const response = await apiServicesClient.user.requestResetPassword(email);
    if (!response.success) {
      toast(response.result.error.message);
      setLoading(false);
      return;
    }

    toast("Please check your email for the link to reset your password.");
    setLoading(false);
  };

  useEffect(() => {
    if (user?.email !== undefined) {
      setEmail(user.email);
    }
  }, [user]);

  return (
    <Container maxWidth="md" className={classes.container}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h3">Reset Password via Email</Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <TextField
          className={classes.inputTextField}
          type="email"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled={user !== null}
        />
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={sendResetPasswordEmailButtonOnClick}
          disabled={loading}
        >
          {loading ? (
            <BeatLoader color="white" loading={true} size={10} />
          ) : (
            "Send Reset Password Email"
          )}
        </Button>
      </Box>
    </Container>
  );
};

export default RequestResetPassword;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
}));
