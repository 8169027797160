import { useContext, useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import userContext from "../context/userContext";
import Select from "../components/Select";
import { saveToLocalStorage } from "../localStorage/localStorage";
import Skeleton from "react-loading-skeleton";

export default function PointSelect({ size, allowUpdatePoint }) {
  const classes = useStyles();
  const {
    currentPoint,
    setCurrentPoint,
    allPoints,
    appSettings,
    setAppSettings,
  } = useContext(userContext);

  const setCurrentPointUpdateModeToManual = () => {
    if (!allowUpdatePoint) {
      return;
    }

    if (appSettings?.currentPointUpdateMode === "manual") {
      return;
    }

    const updatedAppSettings = appSettings;
    updatedAppSettings.currentPointUpdateMode = "manual";
    setAppSettings(updatedAppSettings);

    saveToLocalStorage("appSettings", updatedAppSettings);

    toast("Location update mode is now set to manual.");
  };

  useEffect(() => {
    console.log("Current point changed");
  }, [currentPoint]);

  if (allPoints.length === 0) {
    return (
      <Box
        width="100%"
        maxWidth="sm"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box width="50%">
          <Skeleton
            circle
            style={{
              width: 0.4 * window.innerWidth * (size === "small" ? 0.4 : 1),
              height: 0.4 * window.innerWidth * (size === "small" ? 0.4 : 1),
              maxWidth: 240,
              maxHeight: 240,
            }}
          />
        </Box>

        <Box width="50%">
          <Skeleton height={50} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      maxWidth="sm"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        width="50%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar
          src={currentPoint?.avatarImageUrl}
          alt={currentPoint?.name ? currentPoint?.name : "?"}
          sx={{
            width: 0.4 * window.innerWidth * (size === "small" ? 0.4 : 1),
            height: 0.4 * window.innerWidth * (size === "small" ? 0.4 : 1),
            maxWidth: 240,
            maxHeight: 240,
          }}
        />
      </Box>

      <Box width="50%">
        <Select
          placeholder="Unknown Location"
          selectedValue={currentPoint?.name ? currentPoint?.name : ""}
          setSelectedValue={(newValue) => {
            for (let i = 0; i < allPoints.length; i++) {
              if (allPoints[i].name === newValue) {
                setCurrentPoint(allPoints[i]);
                saveToLocalStorage("currentPoint", allPoints[i]);
                setCurrentPointUpdateModeToManual();
                break;
              }
            }
          }}
          allValues={allPoints.map((point) => point.name)}
        />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  selectMain: {
    width: "100%",
  },
}));
