import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { truncate } from "../utils/stringUtils";

export default function PatientLogListItem({ patientLogDetails, index }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleListItemClick = (event) => {
    navigate(`/patient-logs/${patientLogDetails._id}`);
  };

  return (
    <Box
      className={classes.listItemContainer}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor={index % 2 === 0 ? "#EEEEEE" : "#DEDEDE"}
      borderRadius={1}
      p={1}
      mb={2}
      onClick={handleListItemClick}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Typography>
            {new Date(patientLogDetails.logDatetime).toString().slice(0, 24)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography>{truncate(patientLogDetails.point.name, 15)}</Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography>{truncate(patientLogDetails.notes, 35)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#CCC",
    },
  },
}));
