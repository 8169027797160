import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function PatientDocumentListItem({
  patientDocumentDetails,
  index,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleListItemClick = (event) => {
    navigate(`/patient-documents/${patientDocumentDetails._id}`);
  };

  return (
    <Box
      className={classes.listItemContainer}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor={index % 2 === 0 ? "#EEEEEE" : "#DEDEDE"}
      borderRadius={1}
      p={1}
      mb={2}
      onClick={handleListItemClick}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Typography noWrap>{patientDocumentDetails.documentType}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography noWrap>{patientDocumentDetails.documentTitle}</Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography noWrap>
            {patientDocumentDetails.documentNotes !== undefined &&
            patientDocumentDetails.documentNotes !== ""
              ? patientDocumentDetails.documentNotes
              : "No notes for this document."}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#CCC",
    },
  },
}));
