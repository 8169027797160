/*
To store in Local Storage
  6 Query fields
  Search Error
  Search Results
  Sorting Key

Only update the single field in local storage, do not change the other fields

*/

import { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import { parsePatientLogObjectForJs } from "../api/apiUtils";
import validators from "../api/validators";
import PatientLogListItemWithAvatarAndName from "../components/PatientLogListItemWithAvatarAndName";
import PatientSelect from "../components/PatientSelect";
import PointSelectNoChange from "../components/PointSelectNoChange";
import CustomDatePicker from "../components/CustomDatePicker";
import PatientLogListHeader from "../components/PatientLogListHeader";
import SortingKeySelect from "../components/SortingKeySelect";
import localStorageKeys from "../localStorage/keys";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../localStorage/localStorage";
import stateContext from "../context/stateContext";

export default function SearchPatientLogs() {
  const classes = useStyles();
  const { user } = useContext(userContext);
  const {
    searchPatientsLogsPageErrorText,
    setSearchPatientsLogsPageErrorText,
    searchPatientsLogsPageSearchQuerySelectedPatient,
    setSearchPatientsLogsPageSearchQuerySelectedPatient,
    searchPatientsLogsPageSearchQuerySelectedPoint,
    setSearchPatientsLogsPageSearchQuerySelectedPoint,
    searchPatientsLogsPageSearchQueryStartDatetime,
    setSearchPatientsLogsPageSearchQueryStartDatetime,
    searchPatientsLogsPageSearchQueryEndDatetime,
    setSearchPatientsLogsPageSearchQueryEndDatetime,
    searchPatientsLogsPageSearchQueryNotes,
    setSearchPatientsLogsPageSearchQueryNotes,
    searchPatientsLogsPageSearchQueryPrescription,
    setSearchPatientsLogsPageSearchQueryPrescription,
    searchPatientsLogsPageSearchResults,
    setSearchPatientsLogsPageSearchResults,
    searchPatientsLogsPageSortingKey,
    setSearchPatientsLogsPageSortingKey,
  } = useContext(stateContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [isSearchQueryValid, setIsSearchQueryValid] = useState(true);

  const sortPatientLogs = (inputPatientLogs, inputSortingKey) => {
    switch (inputSortingKey) {
      case "Name": {
        inputPatientLogs.sort((a, b) => {
          if (a.patient.name > b.patient.name) {
            return 1;
          }
          if (a.patient.name < b.patient.name) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Name Desc": {
        inputPatientLogs.sort((a, b) => {
          if (a.patient.name > b.patient.name) {
            return -1;
          }
          if (a.patient.name < b.patient.name) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "Date & Time": {
        inputPatientLogs.sort((a, b) => {
          const d1 = new Date(a.logDatetime);
          const d2 = new Date(b.logDatetime);
          return d1 - d2;
        });
        break;
      }
      case "Date & Time Desc": {
        inputPatientLogs.sort((a, b) => {
          const d1 = new Date(a.logDatetime);
          const d2 = new Date(b.logDatetime);
          return d2 - d1;
        });
        break;
      }
      case "Notes": {
        inputPatientLogs.sort((a, b) => {
          if (a.notes > b.notes) {
            return 1;
          }
          if (a.notes < b.notes) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "Notes Desc": {
        inputPatientLogs.sort((a, b) => {
          if (a.notes > b.notes) {
            return -1;
          }
          if (a.notes < b.notes) {
            return 1;
          }
          return 0;
        });
        break;
      }
      default: {
      }
    }

    return inputPatientLogs;
  };

  const handleSearchButtonClick = async (event) => {
    setIsLoading(true);
    setSearchPatientsLogsPageErrorText("");

    const searchParams = {};
    if (
      searchPatientsLogsPageSearchQuerySelectedPatient !== null &&
      searchPatientsLogsPageSearchQuerySelectedPatient?._id !== undefined
    ) {
      searchParams.patientId =
        searchPatientsLogsPageSearchQuerySelectedPatient._id;
    }
    if (
      searchPatientsLogsPageSearchQuerySelectedPoint !== null &&
      searchPatientsLogsPageSearchQuerySelectedPoint?._id !== undefined
    ) {
      searchParams.pointId = searchPatientsLogsPageSearchQuerySelectedPoint._id;
    }
    if (searchPatientsLogsPageSearchQueryStartDatetime !== null) {
      searchParams.startTimestamp =
        searchPatientsLogsPageSearchQueryStartDatetime.getTime();
    }
    if (searchPatientsLogsPageSearchQueryEndDatetime !== null) {
      searchParams.endTimestamp =
        searchPatientsLogsPageSearchQueryEndDatetime.getTime();
    }
    if (searchPatientsLogsPageSearchQueryNotes !== "") {
      searchParams.notes = searchPatientsLogsPageSearchQueryNotes
        .trimStart()
        .trimEnd();
    }
    if (searchPatientsLogsPageSearchQueryPrescription !== "") {
      searchParams.prescription = searchPatientsLogsPageSearchQueryPrescription
        .trimStart()
        .trimEnd();
    }

    const response = await apiClient.patientLog.search(searchParams);
    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsLoading(false);
      return;
    }

    if (response.result.patientLogs.length === 0) {
      setSearchPatientsLogsPageErrorText("No logs.");
    }

    const patientLogs = response.result.patientLogs.map((patientLog) =>
      parsePatientLogObjectForJs(patientLog)
    );
    setSearchPatientsLogsPageSearchResults(
      sortPatientLogs(patientLogs, searchPatientsLogsPageSortingKey)
    );

    setIsLoading(false);
    setIsLoadingFirstTime(false);
  };

  useEffect(() => {
    document.title = "Search Patient Logs";
  }, []);

  useEffect(() => {
    return;
    const validationErrors = validators.patientLogs.search(
      searchPatientsLogsPageSearchQuerySelectedPatient?._id,
      searchPatientsLogsPageSearchQuerySelectedPoint?._id,
      searchPatientsLogsPageSearchQueryStartDatetime?.getTime(),
      searchPatientsLogsPageSearchQueryEndDatetime?.getTime(),
      searchPatientsLogsPageSearchQueryNotes,
      searchPatientsLogsPageSearchQueryPrescription
    );

    if (Object.keys(validationErrors).length === 0) {
      setIsSearchQueryValid(true);
      setSearchPatientsLogsPageErrorText("");
      return;
    }

    setIsSearchQueryValid(false);

    let combinedValidationErrors = "";
    for (const key in validationErrors) {
      combinedValidationErrors += validationErrors[key] + " ";
    }
    setSearchPatientsLogsPageErrorText(combinedValidationErrors);
  }, [
    searchPatientsLogsPageSearchQuerySelectedPatient,
    searchPatientsLogsPageSearchQuerySelectedPoint,
    searchPatientsLogsPageSearchQueryStartDatetime,
    searchPatientsLogsPageSearchQueryEndDatetime,
    searchPatientsLogsPageSearchQueryNotes,
    searchPatientsLogsPageSearchQueryPrescription,
  ]);

  useEffect(() => {
    if (isLoadingFirstTime) {
      return;
    }

    let updatedPatientLogs = [...searchPatientsLogsPageSearchResults];
    updatedPatientLogs = sortPatientLogs(
      updatedPatientLogs,
      searchPatientsLogsPageSortingKey
    );
    setSearchPatientsLogsPageSearchResults(updatedPatientLogs);
  }, [searchPatientsLogsPageSortingKey]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.searchPatientLogsPageContainer}>
      <Container maxWidth="md" className={classes.container}>
        <PatientSelect
          // defaultSelectedPatientId={
          //   searchPatientsLogsPageSearchQuerySelectedPatient !== null
          //     ? searchPatientsLogsPageSearchQuerySelectedPatient._id
          //     : null
          // }
          selectedPatient={searchPatientsLogsPageSearchQuerySelectedPatient}
          setSelectedPatient={
            setSearchPatientsLogsPageSearchQuerySelectedPatient
          }
          disabled={isLoading}
        />

        <PointSelectNoChange
          // defaultSelectedPointId={
          //   searchPatientsLogsPageSearchQuerySelectedPoint !== null
          //     ? searchPatientsLogsPageSearchQuerySelectedPoint._id
          //     : null
          // }
          selectedPoint={searchPatientsLogsPageSearchQuerySelectedPoint}
          setSelectedPoint={setSearchPatientsLogsPageSearchQuerySelectedPoint}
          disabled={isLoading}
        />

        <Box mb={2}>
          <Accordion width="100%" sx={{ backgroundColor: "inherit" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Additional Fields</Typography>
            </AccordionSummary>
            <AccordionDetails width="100%">
              <CustomDatePicker
                label="Start DateTime"
                selected={searchPatientsLogsPageSearchQueryStartDatetime}
                onChange={(datetime) =>
                  setSearchPatientsLogsPageSearchQueryStartDatetime(datetime)
                }
                disabled={false}
                showTimeSelect={true}
                handleClearButtonClick={() => {
                  setSearchPatientsLogsPageSearchQueryStartDatetime(null);
                }}
                mb={2}
              />

              <CustomDatePicker
                label="End DateTime"
                selected={searchPatientsLogsPageSearchQueryEndDatetime}
                onChange={(datetime) =>
                  setSearchPatientsLogsPageSearchQueryEndDatetime(datetime)
                }
                disabled={false}
                showTimeSelect={true}
                handleClearButtonClick={() => {
                  setSearchPatientsLogsPageSearchQueryEndDatetime(null);
                }}
                mb={2}
              />

              <Box maxWidth="sm" width="100%" mb={2}>
                <TextField
                  className={classes.inputTextField}
                  type="text"
                  value={searchPatientsLogsPageSearchQueryNotes}
                  onChange={(event) =>
                    setSearchPatientsLogsPageSearchQueryNotes(
                      event.target.value
                    )
                  }
                  label="Search by Log Notes"
                  disabled={isLoading}
                />
              </Box>

              <Box maxWidth="sm" width="100%" mb={2}>
                <TextField
                  className={classes.inputTextField}
                  type="text"
                  value={searchPatientsLogsPageSearchQueryPrescription}
                  onChange={(event) =>
                    setSearchPatientsLogsPageSearchQueryPrescription(
                      event.target.value
                    )
                  }
                  label="Search by Prescription"
                  disabled={isLoading}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchButtonClick}
            disabled={isLoading || !isSearchQueryValid}
          >
            {isLoading ? (
              <BeatLoader color="black" loading={true} size={10} />
            ) : (
              "Search"
            )}
          </Button>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {searchPatientsLogsPageErrorText !== "" ? (
            <Box mb={2}>
              <Typography variant="h6" color="error">
                {searchPatientsLogsPageErrorText}
              </Typography>
            </Box>
          ) : searchPatientsLogsPageSearchResults.length ===
            0 ? null : window.innerWidth >= 700 ? (
            <PatientLogListHeader
              sortingKey={searchPatientsLogsPageSortingKey}
              setSortingKey={setSearchPatientsLogsPageSortingKey}
            />
          ) : (
            <SortingKeySelect
              sortingKey={searchPatientsLogsPageSortingKey}
              setSortingKey={setSearchPatientsLogsPageSortingKey}
              allSortingKeys={[
                "Name",
                "Date & Time",
                "Notes",
                "Name Desc",
                "Date & Time Desc",
                "Notes Desc",
              ]}
            />
          )}

          {searchPatientsLogsPageSearchResults.map((patientLog, index) => (
            <PatientLogListItemWithAvatarAndName
              patientLogDetails={patientLog}
              index={index}
              key={index}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  searchPatientLogsPageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(10),
  },
  inputTextField: {
    width: "100%",
  },
}));
